import React, { useEffect, useState } from 'react'
import { FieldArray, useField } from 'formik'
import { FormattedMessage, useIntl } from 'react-intl'
import { v4 as uuidv4 } from 'uuid'
import { SectionListAddButton } from '../../../shared/section/SectionListAddButton'
import {
  Section,
  SectionItem,
  SectionList,
  SectionTitle,
} from '../../../shared/section'
import { ContentArea } from '../../../shared/content'
import { uploadFile } from '../api'
import { FileReference, FileToUpload } from '../types'
import { SectionListFormError } from '../../../shared/section/SectionListFormError'
import { translationsShared } from '../../../shared/translations/translationsShared'
import { overrideFile } from '../db/files'
import { ActivityFieldState } from '../../activity/types'
import { FileFormItem } from './FileFormItem'
import { FileToUploadFormItem } from './FileToUploadFormItem'

type Props = {
  onUploading: (isUploading: boolean) => void
  name: string
  defaultDescripton?: string
  fieldState?: ActivityFieldState
}

const FileFormSection: React.FC<Props> = ({
  name,
  onUploading,
  defaultDescripton,
  fieldState,
}) => {
  const [filesToUpload, setFilesToUpload] = useState<Array<FileToUpload>>([])
  const intl = useIntl()
  const [field] = useField<Array<FileReference>>(name)
  const hiddenFileInput = React.useRef<HTMLInputElement>(null)

  useEffect(
    () => (filesToUpload.length === 0 ? onUploading(false) : onUploading(true)),
    [filesToUpload, onUploading]
  )

  const openFileSelectionDialog = () => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click()
    }
  }

  const onFileSelection = ({
    target,
  }: {
    target: EventTarget & HTMLInputElement
  }) => {
    if (target.files) {
      for (let i = 0; i < target.files.length; i++) {
        const file = target.files.item(i)

        if (file === null) break

        const fileToUpload: FileToUpload = {
          fileId: uuidv4(),
          file: file,
        }
        setFilesToUpload((previousFiles) => [...previousFiles, fileToUpload])
      }
    }
    target.value = '' // clear the event to make re-selection of the same file possible
  }

  const removeFileToUpload = (fileToUpload: FileToUpload) => {
    setFilesToUpload((previousFiles) =>
      previousFiles.filter((file) => file.fileId !== fileToUpload.fileId)
    )
  }

  return (
    <ContentArea>
      <div className="mb-10 space-y-5">
        <Section>
          <SectionTitle>
            <FormattedMessage {...translationsShared.fileFormTitle} />
          </SectionTitle>
          <SectionList>
            <FieldArray
              name={name}
              render={(helper) => {
                return (
                  <>
                    {field.value && field.value.length > 0 ? (
                      field.value.map((file, index) => (
                        <FileFormItem
                          key={index}
                          file={file}
                          name={`files.${index}`}
                          onDelete={() => {
                            helper.remove(index)
                          }}
                          canDelete={
                            fieldState !== undefined &&
                            fieldState !== ActivityFieldState.ReadOnly
                          }
                        />
                      ))
                    ) : (
                      <SectionItem>
                        <FormattedMessage
                          {...translationsShared.fileFormNoFiles}
                        />
                      </SectionItem>
                    )}
                    {filesToUpload.map((fileToUpload) => (
                      <FileToUploadFormItem
                        key={fileToUpload.fileId}
                        fileToUpload={fileToUpload}
                        onUpload={uploadFile}
                        onSuccess={() => {
                          helper.push({
                            fileId: fileToUpload.fileId,
                            description: defaultDescripton ?? '',
                          })
                          removeFileToUpload(fileToUpload)
                          void overrideFile({
                            id: fileToUpload.fileId,
                            fileName: fileToUpload.file.name,
                          })
                        }}
                        onDelete={() => removeFileToUpload(fileToUpload)}
                      />
                    ))}
                  </>
                )
              }}
            />
            <SectionListFormError name="files" />
          </SectionList>
        </Section>
        {fieldState && fieldState !== ActivityFieldState.ReadOnly && (
          <SectionListAddButton
            text={intl.formatMessage(translationsShared.fileFormAdd)}
            onClick={openFileSelectionDialog}
          />
        )}
        <input
          className="hidden"
          ref={hiddenFileInput}
          onChange={onFileSelection}
          type="file"
          multiple
        />
      </div>
    </ContentArea>
  )
}

export { FileFormSection }
