import React from 'react'
import { IconKeyboardArrowRight } from '../../../shared/svgs'

const FormDialogSelectItem: React.FC<{ onClick: () => void }> = ({
  children,
  onClick,
}) => {
  return (
    <button
      className="flex w-full items-center space-x-2 px-3 py-5 text-left md:space-x-4 md:px-5 md:py-4"
      type="button"
      onClick={onClick}
    >
      <div className="flex-1">{children}</div>
      <div>
        <IconKeyboardArrowRight className="size-6" />
      </div>
    </button>
  )
}

export { FormDialogSelectItem }
