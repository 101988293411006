import React, { ReactElement, useContext } from 'react'
import {
  FormattedDate,
  FormattedMessage,
  FormattedTime,
  useIntl,
} from 'react-intl'
import classnames from 'classnames'
import IconReadMore from '@aboutbits/react-material-icons/dist/IconReadMore'
import IconInfoOutlined from '@aboutbits/react-material-icons/dist/IconInfoOutlined'
import { SectionItem } from '../../../shared/section'
import { Badge } from '../../../shared/badge'
import { IconCheck, IconEventAvailable } from '../../../shared/svgs'
import { RESOURCE_TYPE_ICONS, ViewNotification } from '../types'
import { getNotificationLink } from '../utils'
import { Activity, Group } from '../../activity/types'
import {
  translationsNotification,
  translationsNotificationType,
} from '../translations/translationsNotification'
import { SectionItemActionContainer } from '../../../shared/section/SectionItemActionContainer'
import {
  SectionItemActionButton,
  SectionItemActionLink,
} from '../../../shared/section/SectionItemActionButton'
import { translationsShared } from '../../../shared/translations/translationsShared'
import { AppContext } from '../../../app/AppContext'
import { archiveNotification } from '../api'
import { overrideNotification } from '../db/notifications'
import { extractErrorMessage } from '../../../shared/utils/apiUtils'
import { useGetActivityLink } from '../../activity/utils'
import { getProjectLink } from '../../project/utils'
import { getAddressLink } from '../../address/utils'
import { ResourceType } from '../../../shared/utils/resourceTypes'

type Props = {
  notification: ViewNotification
  showAddress?: boolean
  showReferencedResource?: boolean
  showActions: boolean
  showGoToAction: boolean
  activityGroups: Group[] | null
  activities: Activity[] | null
  onClick: (value: string) => void
}

function NotificationItem({
  notification,
  showAddress,
  showReferencedResource,
  showActions,
  showGoToAction,
  activityGroups,
  activities,
  onClick,
}: Props): ReactElement {
  const intl = useIntl()
  const { addAlertErrorMessage } = useContext(AppContext)

  const hasBadges =
    (showReferencedResource && notification.referencedResourceName) ||
    notification.time

  const activity = activities?.find(
    (activity) => notification.referencedResourceId === activity.id
  )
  const notificationLink = getNotificationLink(
    notification,
    activityGroups,
    activity
  )

  const onArchive = async () => {
    try {
      const updatedNotification = await archiveNotification(notification)
      await overrideNotification(updatedNotification)
    } catch (error: any) {
      addAlertErrorMessage(extractErrorMessage(error))
    }
  }

  return (
    <div
      className={classnames(
        showActions ? 'bg-white shadow-lg' : '',
        'divide-y divide-gray-500'
      )}
    >
      <button
        className="block w-full text-left focus:outline-none"
        onClick={() => onClick(notification.id)}
      >
        <SectionItem>
          <div className="flex-1 overflow-hidden">
            <div>
              <FormattedMessage
                {...translationsNotificationType(notification.type)}
              />
              {showAddress && notification.assignedName
                ? ' - ' + notification.assignedName
                : null}
            </div>
            {hasBadges ? (
              <div className="mt-2 flex flex-wrap gap-x-3 gap-y-1">
                {showReferencedResource &&
                notification.referencedResourceName ? (
                  <Badge
                    icon={
                      RESOURCE_TYPE_ICONS[notification.referencedResourceType]
                    }
                  >
                    {notification.referencedResourceName}
                  </Badge>
                ) : null}
                {notification.time ? (
                  <Badge icon={IconEventAvailable}>
                    <FormattedDate value={new Date(notification.time)} />{' '}
                    <FormattedTime value={new Date(notification.time)} />
                  </Badge>
                ) : null}
              </div>
            ) : null}
            {notification.message ? (
              <div className="mt-3 line-clamp-2 whitespace-pre text-base">
                {notification.message}
              </div>
            ) : null}
          </div>
        </SectionItem>
      </button>
      {showActions && (
        <SectionItemActionContainer>
          <SectionItemActionButton
            onClick={onArchive}
            icon={IconCheck}
            label={intl.formatMessage(translationsNotification.archive)}
          />
          {showGoToAction && (
            <>
              {notification.referencedResourceType === ResourceType.project && (
                <GoToProjectActionLink id={notification.referencedResourceId} />
              )}
              {notification.referencedResourceType === ResourceType.address && (
                <GoToAddressActionLink id={notification.referencedResourceId} />
              )}
              {notification.referencedResourceType ===
                ResourceType.activity && (
                <GoToActivityActionLink
                  id={notification.referencedResourceId}
                />
              )}
            </>
          )}
          <SectionItemActionLink
            link={notificationLink}
            icon={IconInfoOutlined}
            label={intl.formatMessage(translationsShared.details)}
          />
          <SectionItemActionLink
            link={notificationLink + '/edit'}
            label={intl.formatMessage(translationsShared.edit)}
          />
        </SectionItemActionContainer>
      )}
    </div>
  )
}

function GoToActionLink({ link }: { link: string | null }) {
  const intl = useIntl()

  if (!link) return null

  return (
    <SectionItemActionLink
      link={link}
      icon={IconReadMore}
      label={intl.formatMessage({
        id: 'notification.overview.goToResource',
        defaultMessage: 'Go to resource',
      })}
    />
  )
}

function GoToProjectActionLink({ id }: { id: string }) {
  const link = getProjectLink(id)

  return <GoToActionLink link={link} />
}

function GoToAddressActionLink({ id }: { id: string }) {
  const link = getAddressLink(id)

  return <GoToActionLink link={link} />
}

function GoToActivityActionLink({ id }: { id: string }) {
  const link = useGetActivityLink(id)

  return <GoToActionLink link={link} />
}

export { NotificationItem }
