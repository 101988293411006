import React from 'react'
import { FormattedMessage } from 'react-intl'

import { useNavigate } from 'react-router-dom'
import { ActionButton } from '../../../../shared/button/ActionButton'
import { IconEdit } from '../../../../shared/svgs'

const TimeTrackingItemEdit: React.FC<{ id: string }> = ({ id }) => {
  const navigate = useNavigate()

  const navigateToEditForm = () => {
    navigate('/time-tracking/' + id + '/edit')
  }

  return (
    <ActionButton onClick={navigateToEditForm}>
      <IconEdit className="mr-2 size-5" />
      <FormattedMessage id="timeTracking.item.edit" defaultMessage="Edit" />
    </ActionButton>
  )
}

export { TimeTrackingItemEdit }
