import axios, { AxiosError } from 'axios'
import { format } from 'date-fns'
import set from 'lodash.set'

export type ErrorBody = {
  message?: string
}

export type FormErrorBody = ErrorBody & {
  errors: {
    [key: string]: string[]
  }
}

type NestedErrorMessage = {
  [key: string]: NestedErrorMessage | string
}

export const joinFieldErrorMessages = (errors: {
  [key: string]: string[] | null
}): { [key: string]: NestedErrorMessage } => {
  if (!errors && typeof errors !== 'object') {
    return {}
  }

  return Object.keys(errors).reduce((acc, key) => {
    if (errors[key] !== null && Array.isArray(errors[key])) {
      const errorString = (errors[key] as string[]).join(', ')
      set(acc, key, errorString)
    }
    return acc
  }, {})
}

export async function axiosGet<T>(path: string): Promise<T> {
  const res = await axios.get(path)
  return res.data
}

export function extractErrorMessage(error: AxiosError<ErrorBody>): string {
  if (error.response && error.response.data.message) {
    return error.response.data.message
  }

  return 'Network Error'
}

export function formatDateAndTimeForAPI(date: string, time: string): string {
  return date + 'T' + time + ':00'
}

export function formatDateForAPI(date: string | Date): string {
  date = typeof date === 'string' ? new Date(date) : date
  return format(date, 'yyyy-MM-dd HH:mm:ss').split(' ').join('T')
}
