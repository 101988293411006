import React, { useState } from 'react'
import { v4 as uuid } from 'uuid'
import { Alert, AlertMessage, AlertType } from '../shared/alert/AlertMessage'

type ContextValue = {
  addAlertErrorMessage: (message: string) => void
}

const initialContext = {
  addAlertErrorMessage: () => {},
}

const AppContext = React.createContext<ContextValue>(initialContext)

const AppContainer: React.FC = ({ children }) => {
  const [alerts, setAlerts] = useState<Alert[]>([])

  return (
    <AppContext.Provider
      value={{
        addAlertErrorMessage: (message: string) => {
          setAlerts((previousMessages) => {
            return previousMessages.concat([
              {
                id: uuid(),
                type: AlertType.ERROR,
                message,
              },
            ])
          })
        },
      }}
    >
      {children}
      <div className="fixed bottom-0 right-0 mx-4 mb-4 flex flex-col items-end space-y-2">
        {alerts.map((alert, index) => {
          return (
            <AlertMessage
              key={alert.id}
              alert={alert}
              onRemove={() => {
                setAlerts((previousAlerts) => {
                  let nextAlerts = previousAlerts.concat([])
                  nextAlerts.splice(index, 1)
                  return nextAlerts
                })
              }}
            />
          )
        })}
      </div>
    </AppContext.Provider>
  )
}

export { AppContext, AppContainer }
