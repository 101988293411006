import React from 'react'

const SectionItemActionContainer: React.FC = ({ children }) => {
  return (
    <div className="flex flex-row divide-x divide-gray-500 bg-white">
      {children}
    </div>
  )
}

export { SectionItemActionContainer }
