import React, { useContext } from 'react'
import { v4 as uuid } from 'uuid'
import { useNavigate } from 'react-router-dom'
import { FormikHelpers } from 'formik'
import { addMinutes } from 'date-fns'
import { AsyncView } from '@aboutbits/react-toolbox'
import { FormattedMessage } from 'react-intl'
import { ActivityFormFields } from '../form/types'
import { ActivityForm } from '../form/ActivityForm'
import { Activity, Category, Group } from '../types'
import { createActivity, createActivityHierarchy } from '../api'
import {
  overrideActivity,
  overrideActivityHierarchy,
  useGetActivityById,
  useGetActivityGroup,
} from '../db/activities'
import {
  extractErrorMessage,
  formatDateAndTimeForAPI,
  formatDateForAPI,
} from '../../../shared/utils/apiUtils'
import {
  extractDateFromTimestamp,
  extractTimeFromTimestamp,
} from '../../../shared/utils/dateUtils'
import { AuthContext } from '../../../auth/AuthContext'
import { AppContext } from '../../../app/AppContext'
import { createNotification } from '../../notification/api'
import { Notification } from '../../notification/types'
import { overrideNotification } from '../../notification/db/notifications'
import { ResourceType } from '../../../shared/utils/resourceTypes'
import { Error } from '../../../shared/error'
import { replaceUserAndDate } from '../form/replaceUserAndDate'
import { LoadingBlock } from '../../../shared/loading'
import { useGetFilteredCategoriesForActivityGroup } from '../utils'
import { Me } from '../../../auth/types'

type Props = {
  addressId: string | null
  projectId: string | null
  parentId: string | null
  activityGroupId: string
}

const ActivityAddSection: React.FC<Props> = (props) => {
  const { data: groupDate, error } = useGetActivityGroup(props.activityGroupId)

  return groupDate ? (
    <AsyncView
      data={groupDate}
      error={error}
      renderSuccess={(activityGroup) => (
        <ActivityAddSectionForm {...props} activityGroup={activityGroup} />
      )}
      renderError={(activityError) => (
        <Error text={activityError && activityError.message} />
      )}
    />
  ) : (
    <></>
  )
}

function ActivityAddSectionForm({
  addressId,
  projectId,
  parentId,
  activityGroup,
}: Props & { activityGroup: Group }) {
  const navigate = useNavigate()
  const { addAlertErrorMessage } = useContext(AppContext)

  const categories = useGetFilteredCategoriesForActivityGroup(activityGroup)
  const { data: me } = useContext(AuthContext)

  if (!categories || !me) return null

  const initialValues = getInitialValues(
    addressId,
    projectId,
    activityGroup,
    categories,
    me
  )

  const onSubmit = async (
    values: ActivityFormFields,
    { resetForm }: FormikHelpers<ActivityFormFields>
  ) => {
    try {
      const activityId = uuid()
      const apiActivity: Activity = {
        ...values,
        id: activityId,
        search: values.name,
        sort: values.name,
        activityCategoryId:
          values.activityCategoryId === '' ? null : values.activityCategoryId,
        activityTypeId:
          values.activityTypeId === '' ? null : values.activityTypeId,
        activityProgressId:
          values.activityProgressId === '' ? null : values.activityProgressId,
        startDate: formatDateAndTimeForAPI(values.startDate, values.startTime),
        endDate: formatDateAndTimeForAPI(values.endDate, values.endTime),
        dueDate:
          values.dueDate && values.dueTime
            ? formatDateAndTimeForAPI(values.dueDate, values.dueTime)
            : null,
        signature: null,
      }

      const activity = await createActivity(apiActivity)
      await overrideActivity(activity)

      for (const notification of values.notifications) {
        var result = await createNotification({
          ...(notification as Notification),
          referencedResourceType: ResourceType.activity,
          referencedResourceId: activityId,
        })

        await overrideNotification(result)
      }

      if (parentId) {
        const activityHierarchy = {
          parentId,
          childId: activityId,
        }

        await createActivityHierarchy(activityHierarchy)
        await overrideActivityHierarchy(activityHierarchy)
      }

      resetForm({ values })

      navigate(-1)
    } catch (error: any) {
      addAlertErrorMessage(extractErrorMessage(error))
    }
  }

  return (
    <>
      {parentId && <ParentActivityInfo parentId={parentId} />}
      <ActivityForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        activityGroup={activityGroup}
      />
    </>
  )
}

const ParentActivityInfo: React.FC<{ parentId: string }> = ({ parentId }) => {
  const { data: parentActivity } = useGetActivityById(parentId)

  return (
    <div className="mt-8 md:mt-10">
      <span className="font-medium">
        <FormattedMessage
          id="activity.add.parentActivity"
          defaultMessage="Parent activity"
        />
      </span>
      :{' '}
      {parentActivity ? (
        parentActivity.name
      ) : (
        <LoadingBlock className="inline-block w-full max-w-40" />
      )}
    </div>
  )
}

export function getInitialValues(
  addressId: string | null,
  projectId: string | null,
  groupConfig: Group,
  categories: Category[],
  me: Me
): ActivityFormFields {
  const categoryId = getDefault(
    groupConfig.fields.activityCategoryId.default,
    ''
  )
  const category = categories.find((category) => category.id === categoryId)
  let progress = category?.progresses.find((progress) => {
    return progress.id === category.defaultProgressId
  })
  const typeId = category?.defaultTypeId ?? ''
  const progressId =
    category?.defaultProgressId &&
    progress &&
    progress.activityGroupIds.includes(groupConfig.id)
      ? category?.defaultProgressId
      : ''

  const startDate = formatDateForAPI(
    addMinutes(new Date(), groupConfig.fields.startDate.default ?? 0)
  )
  const endDate = formatDateForAPI(
    addMinutes(new Date(), groupConfig.fields.endDate.default ?? 30)
  )
  const dueDate =
    groupConfig.fields.dueDate.default != null
      ? formatDateForAPI(
          addMinutes(new Date(), groupConfig.fields.dueDate.default)
        )
      : ''

  return {
    name: getDefault(
      replaceUserAndDate(
        groupConfig.fields.name.default,
        me.user.username ?? ''
      ),
      ''
    ),
    description: getDefault(
      replaceUserAndDate(
        groupConfig.fields.description.default,
        me.user.username ?? ''
      ),
      ''
    ),
    activityCategoryId: categoryId,
    activityTypeId: typeId,
    activityProgressId: progressId,
    startDate: extractDateFromTimestamp(startDate),
    startTime: extractTimeFromTimestamp(startDate),
    endDate: extractDateFromTimestamp(endDate),
    endTime: extractTimeFromTimestamp(endDate),
    dueDate: dueDate === '' ? '' : extractDateFromTimestamp(startDate),
    dueTime: dueDate === '' ? '' : extractTimeFromTimestamp(startDate),
    billable:
      groupConfig.fields.billable.default != null
        ? groupConfig.fields.billable.default
        : false,
    projectId:
      projectId || getDefault(groupConfig.fields.projectId.default, ''),
    addressId:
      addressId || getDefault(groupConfig.fields.addressId.default, ''),
    articles:
      groupConfig.fields.articles.default == null
        ? []
        : groupConfig.fields.articles.default.map((article) => {
            return {
              ...article,
              id: uuid(),
              count: article.count.toString(),
            }
          }),
    notifications: [],
    files: [],
    assignedAddressIds: [me.user.id],
    assignedAddressGroupIds: [],
    startDateChanged: false,
    endDateChanged: false,
    durationChanged: false,
    startTimeChanged: false,
    endTimeChanged: false,
  }
}

function getDefault(
  configDefault: string | null | undefined,
  systemDefault: string
): string {
  return configDefault == null ? systemDefault : configDefault
}

export { ActivityAddSection }
