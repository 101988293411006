import { Tab, useTabsContext } from '@reach/tabs'
import classnames from 'classnames'
import React, { useEffect, useRef } from 'react'

export const TabListElement: React.FC<{
  index: number
}> = ({ index, children, ...props }) => {
  const { selectedIndex } = useTabsContext()
  const customCss =
    selectedIndex === index
      ? 'border-primary-400 md:bg-primary-400'
      : 'border-white hover:border-primary-300 active:border-primary-300 focus:border-primary-300 md:hover:bg-primary-300'

  const tabRef = useRef<HTMLButtonElement>(null)
  const firstRender = useRef(true)

  useEffect(() => {
    if (firstRender.current && selectedIndex === index) {
      const tab = tabRef.current
      const tabList = tab?.parentElement
      if (tabList) {
        tabList.scrollLeft = tab.getBoundingClientRect().left
      }
    }
  }, [selectedIndex, index])

  useEffect(() => {
    firstRender.current = false
  }, [])

  return (
    <Tab
      ref={tabRef}
      className={classnames(
        'border-b-2 px-6 py-4 text-xs font-medium uppercase focus:outline-none md:rounded-full md:border-none md:px-4 md:py-1 md:font-medium',
        customCss
      )}
      index={index}
      {...props}
    >
      {children}
    </Tab>
  )
}
