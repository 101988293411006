import React from 'react'
import { useIntl } from 'react-intl'

import { HeaderRightArea } from '../header'
import { IconSearch } from '../svgs'
import { translationsProject } from '../../domain/project/translations/translationsProject'
import { DialogAction } from './DialogAction'

const DialogStartSearchAction: React.FC<{
  startSearch: () => void
  label: string
}> = ({ label, startSearch }) => {
  const intl = useIntl()

  return (
    <HeaderRightArea className="self-end md:self-start">
      <DialogAction
        label={intl.formatMessage(translationsProject.overviewSearchStart)}
        onClick={startSearch}
        className="-mb-4 -mr-4 rounded-full bg-gray-700 text-white hover:bg-gray-600 md:mb-0 md:mr-0"
      >
        <IconSearch className="size-6 fill-current md:size-8" title={label} />
      </DialogAction>
    </HeaderRightArea>
  )
}

export { DialogStartSearchAction }
