import { ReactElement } from 'react'
import { Dialog } from '@reach/dialog'
import { FormattedMessage, useIntl } from 'react-intl'
import { AsyncView } from '@aboutbits/react-toolbox'
import { Link } from 'react-router-dom'
import { ContentArea } from '../../../shared/content'
import { DialogHeaderArea } from '../../../shared/dialog/DialogHeaderArea'
import { HeaderLeftArea } from '../../../shared/header'
import { DialogAction } from '../../../shared/dialog/DialogAction'
import {
  IconClose,
  IconFormatListBulleted,
  IconKeyboardArrowRight,
} from '../../../shared/svgs'
import { DialogTitle } from '../../../shared/dialog/DialogTitle'
import {
  Section,
  SectionItem,
  SectionList,
  SectionTitle,
} from '../../../shared/section'
import { useGetActivityGroups } from '../db/activities'
import { Empty } from '../../../shared/empty'
import { translationsShared } from '../../../shared/translations/translationsShared'
import { translationsActivity } from '../translations/translationsActivity'
import { Group } from '../types'

type ActivityAddQuery = {
  address?: string
  project?: string
  parent?: string
}

type Props = {
  isOpen: boolean
  onDismis: () => void
  query?: ActivityAddQuery
  filter?: (activityGroup: Group) => boolean
}

function ActivityAddDialog({
  isOpen,
  onDismis,
  query,
  filter,
}: Props): ReactElement {
  const intl = useIntl()
  const title = intl.formatMessage(translationsActivity.addTitle)

  const { data } = useGetActivityGroups(filter)

  return (
    <Dialog
      isOpen={isOpen}
      aria-label={title}
      className="mx-auto bg-white md:my-20 md:w-full md:max-w-lg md:bg-transparent"
    >
      <DialogHeaderArea>
        <HeaderLeftArea>
          <DialogAction
            label={intl.formatMessage(translationsShared.close)}
            onClick={onDismis}
            className="rounded-full bg-white hover:bg-gray-300"
          >
            <IconClose className="size-7 fill-current text-gray-700" />
          </DialogAction>
        </HeaderLeftArea>
        <DialogTitle className="text-white">{title}</DialogTitle>
      </DialogHeaderArea>
      <ContentArea>
        <Section>
          <SectionTitle>
            <FormattedMessage
              id="activity.add.activityGroupList.title"
              defaultMessage='"Where should the activity be created?'
            />
          </SectionTitle>
          <AsyncView
            data={data}
            renderSuccess={(activityGroups) => (
              <SectionList>
                {activityGroups != null && activityGroups.length > 0 ? (
                  activityGroups.map((activityGroup) => (
                    <Link
                      key={activityGroup.id}
                      to={getUrl(activityGroup.id, query)}
                      className="block"
                    >
                      <SectionItem key={activityGroup.id}>
                        <div className="flex-1">
                          <h2>{activityGroup.name}</h2>
                        </div>

                        <IconKeyboardArrowRight className="size-6" />
                      </SectionItem>
                    </Link>
                  ))
                ) : (
                  <Empty
                    icon={IconFormatListBulleted}
                    text={intl.formatMessage(
                      translationsActivity.overviewListEmpty
                    )}
                  />
                )}
              </SectionList>
            )}
          />
        </Section>
      </ContentArea>
    </Dialog>
  )
}

const getUrl = (activityGroupId: string, query?: ActivityAddQuery) => {
  const baseUrl = `/activity-groups/${activityGroupId}/activities/add`

  if (query == null) {
    return baseUrl
  }

  const filteredQuery = Object.fromEntries(
    Object.entries(query).filter(
      ([, value]) => value !== null && value !== undefined
    )
  )

  var queryParam = new URLSearchParams(filteredQuery).toString()
  return `${baseUrl}?${queryParam}`
}

export { ActivityAddDialog }
