import React, { PropsWithChildren } from 'react'
import classNames from 'classnames'
import { SectionItem } from '../../section'
import { IconKeyboardArrowRight } from '../../svgs'
import { Size } from '../../section/SectionItem'

type Id = string | number

type Props<T extends Id> = {
  itemId: T
  onSelect: (itemId: T) => void
  className?: string
}

function DialogSelectionListItem<T extends Id>({
  itemId,
  onSelect,
  className,
  children,
}: PropsWithChildren<Props<T>>) {
  return (
    <button
      onClick={() => {
        onSelect(itemId)
      }}
      className={classNames(
        'block w-full hover:bg-gray-500 focus:outline-none',
        className
      )}
    >
      <SectionItem size={Size.sm}>
        {children}
        <div>
          <IconKeyboardArrowRight className="size-5" />
        </div>
      </SectionItem>
    </button>
  )
}

export { DialogSelectionListItem }
