import { useIntl } from 'react-intl'
import { Dialog } from '@reach/dialog'
import React from 'react'
import { DialogHeaderArea } from '../../../../../shared/dialog/DialogHeaderArea'
import { HeaderLeftArea } from '../../../../../shared/header'
import { DialogAction } from '../../../../../shared/dialog/DialogAction'
import { translationsShared } from '../../../../../shared/translations/translationsShared'
import { IconClose } from '../../../../../shared/svgs'
import { DialogTitle } from '../../../../../shared/dialog/DialogTitle'
import { ContentArea } from '../../../../../shared/content'
import { NotificationOverviewList } from '../../../../notification/overview/NotificationOverviewList'
import { Notification } from '../../../../notification/types'

export function ActivityOverviewChipNotificationDialog({
  notifications,
  isOpen,
  setIsOpen,
}: {
  notifications: Notification[] | undefined
  isOpen: boolean
  setIsOpen: (isExpanded: boolean) => void
}) {
  const intl = useIntl()

  const title = intl.formatMessage({
    id: 'activity.overview.notification.dialog.title',
    defaultMessage: 'Notifications',
  })

  return (
    <Dialog
      isOpen={isOpen}
      aria-label={title}
      className="mx-auto bg-white md:my-20 md:w-full md:max-w-lg md:bg-transparent"
    >
      <DialogHeaderArea>
        <HeaderLeftArea>
          <DialogAction
            label={intl.formatMessage(translationsShared.close)}
            onClick={() => setIsOpen(false)}
            className="rounded-full bg-white hover:bg-gray-300"
          >
            <IconClose className="size-7 fill-current text-gray-700" />
          </DialogAction>
        </HeaderLeftArea>
        <DialogTitle className="text-white">{title}</DialogTitle>
      </DialogHeaderArea>
      <ContentArea>
        {notifications && notifications.length > 0 ? (
          <NotificationOverviewList
            title={intl.formatMessage({
              id: 'activity.overview.notification.dialog.section.title',
              defaultMessage: 'Notification List',
            })}
            notifications={notifications}
            showAddress={true}
          />
        ) : null}
      </ContentArea>
    </Dialog>
  )
}
