import { FormattedMessage, useIntl } from 'react-intl'
import { Dialog } from '@reach/dialog'
import React from 'react'
import IconInsertDriveFileOutlined from '@aboutbits/react-material-icons/dist/IconInsertDriveFileOutlined'
import { FileReference } from '../../../types'
import { DialogHeaderArea } from '../../../../../shared/dialog/DialogHeaderArea'
import { HeaderLeftArea } from '../../../../../shared/header'
import { DialogAction } from '../../../../../shared/dialog/DialogAction'
import { translationsShared } from '../../../../../shared/translations/translationsShared'
import { IconClose } from '../../../../../shared/svgs'
import { DialogTitle } from '../../../../../shared/dialog/DialogTitle'
import { ContentArea } from '../../../../../shared/content'
import {
  Section,
  SectionItem,
  SectionList,
  SectionTitle,
} from '../../../../../shared/section'
import { Size } from '../../../../../shared/section/SectionItem'
import { useGetFilesByIds } from '../../../../file/db/files'

export function ActivityOverviewChipFileDialog({
  files,
  isOpen,
  setIsOpen,
}: {
  files: FileReference[]
  isOpen: boolean
  setIsOpen: (isExpanded: boolean) => void
}) {
  const intl = useIntl()
  const { data: remoteFiles } = useGetFilesByIds(
    files.map((file) => file.fileId)
  )

  const title = intl.formatMessage({
    id: 'activity.overview.files.dialog.title',
    defaultMessage: 'Files',
  })

  return (
    <Dialog
      isOpen={isOpen}
      aria-label={title}
      className="mx-auto bg-white md:my-20 md:w-full md:max-w-lg md:bg-transparent"
    >
      <DialogHeaderArea>
        <HeaderLeftArea>
          <DialogAction
            label={intl.formatMessage(translationsShared.close)}
            onClick={() => setIsOpen(false)}
            className="rounded-full bg-white hover:bg-gray-300"
          >
            <IconClose className="size-7 fill-current text-gray-700" />
          </DialogAction>
        </HeaderLeftArea>
        <DialogTitle className="text-white">{title}</DialogTitle>
      </DialogHeaderArea>
      <ContentArea>
        <Section>
          <SectionTitle>
            <FormattedMessage
              id="activity.overview.files.dialog.section.title"
              defaultMessage="File List"
            />
          </SectionTitle>
          {remoteFiles && remoteFiles.length > 0 ? (
            <SectionList>
              {remoteFiles.map(
                (file) =>
                  file && (
                    <SectionItem key={file.id} size={Size.xs}>
                      <IconInsertDriveFileOutlined className="size-6 fill-current" />
                      <h2>{file.fileName}</h2>
                    </SectionItem>
                  )
              )}
            </SectionList>
          ) : null}
        </Section>
      </ContentArea>
    </Dialog>
  )
}
