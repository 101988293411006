import { RemoteFile } from '../types'
import { updateDatabase } from '../../../shared/utils/syncUtil'
import { TABLE_FILES } from '../../../db/types'
import { db } from '../../../db'
import { useGetById, useGetByIds } from '../../../db/hooks'

export async function ovverideFiles(files: Array<RemoteFile>) {
  await updateDatabase(db, TABLE_FILES, files)
}

export async function overrideFile(remoteFile: RemoteFile): Promise<void> {
  await db.table(TABLE_FILES).put({
    ...remoteFile,
  })
}

export const useGetFileById = (fileId: string) => {
  return useGetById<RemoteFile>(TABLE_FILES, fileId)
}

export const useGetFilesByIds = (fileIds: string[]) => {
  return useGetByIds<RemoteFile>(TABLE_FILES, fileIds)
}
