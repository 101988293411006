import React, { ReactElement, useState } from 'react'
import classNames from 'classnames'
import IconFilePresent from '@aboutbits/react-material-icons/dist/IconFilePresent'

type Props = {
  url: string | undefined
  alt?: string
}

export function FilePreview(props: Props): ReactElement {
  const [hasFilePreview, setHasFilePreview] = useState<boolean | undefined>(
    undefined
  )

  return (
    <div className="h-32 w-2/6 rounded-lg bg-gray-500">
      <img
        src={props.url}
        className={classNames(
          'flex object-cover overflow-hidden items-center w-full h-full text-center rounded-lg',
          hasFilePreview !== undefined && !hasFilePreview && 'hidden'
        )}
        onLoad={() => setHasFilePreview(true)}
        onError={() => setHasFilePreview(false)}
        alt={props.alt}
      />
      {hasFilePreview !== undefined && !hasFilePreview && (
        <div className="flex h-full items-center justify-center">
          <IconFilePresent className="size-12" />
        </div>
      )}
    </div>
  )
}
