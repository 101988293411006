import * as Yup from 'yup'
import { useIntl } from 'react-intl'
import { NOTIFICATION_TYPES_FIELDS, NotificationType } from '../types'
import { translationsShared } from '../../../shared/translations/translationsShared'
import { NotificationAssignmentType } from './types'

const useGetCommonValidationSchema = () => {
  const intl = useIntl()

  const requiredField = intl.formatMessage(
    translationsShared.validationRequired
  )

  return Yup.object().shape({
    type: Yup.string().required(requiredField),
    message: Yup.string()
      .max(
        1000,
        intl.formatMessage({
          id: 'notification.form.validation.message',
          defaultMessage: 'The message must not be longer than 255 characters',
        })
      )
      .nullable(),
    time: Yup.date()
      .nullable()
      .when('type', {
        is: (type: NotificationType) =>
          NOTIFICATION_TYPES_FIELDS[type]?.fields.includes('time'),
        then: (schema) => schema.required(requiredField),
      }),
    assignmentType: Yup.string().oneOf(
      Object.values(NotificationAssignmentType)
    ),
  })
}
export const useGetEditNotificationValidationSchema = () => {
  const intl = useIntl()

  const commonValidationSchema = useGetCommonValidationSchema()
  const requiredField = intl.formatMessage(
    translationsShared.validationRequired
  )

  return Yup.object().shape({
    ...commonValidationSchema.fields,
    addressGroupId: Yup.string().when('assignmentType', {
      is: NotificationAssignmentType.addressGroup,
      then: (schema) => schema.required(requiredField),
    }),
    userId: Yup.string().when('assignmentType', {
      is: NotificationAssignmentType.user,
      then: (schema) => schema.required(requiredField),
    }),
  })
}

export const useGetCreateNotificationValidationSchema = () => {
  const intl = useIntl()

  const commonValidationSchema = useGetCommonValidationSchema()
  const requiredField = intl.formatMessage(
    translationsShared.validationRequired
  )

  return Yup.object().shape({
    ...commonValidationSchema.fields,
    addressGroups: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.string(),
          name: Yup.string(),
        })
      )
      .when('assignmentType', {
        is: NotificationAssignmentType.addressGroup,
        then: (schema) => schema.min(1, requiredField),
      }),
    users: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.string(),
          name: Yup.string(),
        })
      )
      .when('assignmentType', {
        is: NotificationAssignmentType.user,
        then: (schema) => schema.min(1, requiredField),
      }),
  })
}
