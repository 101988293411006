import { FormattedMessage } from 'react-intl'
import { useState } from 'react'
import { useGetNotificationsByResource } from '../../../notification/db/notifications'
import { ResourceType } from '../../../../shared/utils/resourceTypes'
import { ListChip } from '../../../../shared/listChip/ListChip'
import { IconNotifications } from '../../../../shared/svgs'
import { ActivityOverviewChipNotificationDialog } from './dialog/ActivityOverviewChipNotificationDialog'

export function ActivityOverviewNotificationChip({
  activityId,
}: {
  activityId: string
}) {
  const { data: notifications } = useGetNotificationsByResource(
    ResourceType.activity,
    activityId
  )
  const [isOpen, setIsOpen] = useState<boolean>(false)

  if (!notifications || notifications.length === 0) {
    return null
  }

  return (
    <>
      <ListChip Icon={IconNotifications} onClick={() => setIsOpen(true)}>
        <>
          <FormattedMessage
            id="activity.overview.list.item.chips.notifications"
            defaultMessage="Notifications"
          />
          {` (${notifications.length})`}
        </>
      </ListChip>
      <ActivityOverviewChipNotificationDialog
        notifications={notifications}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </>
  )
}
