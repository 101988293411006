import { FormattedMessage, useIntl } from 'react-intl'
import { useState } from 'react'
import { useGetChildActivities } from '../../db/activityHierarchies'
import { IconFormatListBulleted } from '../../../../shared/svgs'
import { ListChip } from '../../../../shared/listChip/ListChip'
import { ActivityOverviewChipRelatedActivitiesDialog } from './dialog/ActivityOverviewChipRelatedActivitiesDialog'

export function ActivityOverviewChildActivitiesChip({
  activityId,
}: {
  activityId: string
}) {
  const intl = useIntl()
  const { data: childActivities } = useGetChildActivities(activityId)
  const [isOpen, setIsOpen] = useState<boolean>(false)

  if (!childActivities || childActivities.length === 0) {
    return null
  }

  return (
    <>
      <ListChip Icon={IconFormatListBulleted} onClick={() => setIsOpen(true)}>
        <>
          <FormattedMessage
            id="activity.overview.list.item.chips.subActivities"
            defaultMessage="Child activities"
          />
          {` (${childActivities.length})`}
        </>
      </ListChip>
      <ActivityOverviewChipRelatedActivitiesDialog
        relatedActivities={childActivities}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        dialogTitle={intl.formatMessage({
          id: 'activity.overview.childActivities.dialog.title',
          defaultMessage: 'Child Activities',
        })}
        sectionTitle={intl.formatMessage({
          id: 'activity.overview.childActivities.dialog.section.title',
          defaultMessage: 'Child Activities List',
        })}
      />
    </>
  )
}
