import { mergeEventHandlers } from '../utils/mergeEventHandlers'
import {
  MultiSelectWrapper,
  MultiSelectWrapperProps,
  SelectInput,
} from './multiSelect/Select'

export type MultiSelectFieldProps<Option, Error = unknown> = Pick<
  MultiSelectWrapperProps<Option, Error>,
  | 'options'
  | 'value'
  | 'defaultValue'
  | 'by'
  | 'error'
  | 'placeholder'
  | 'renderValue'
  | 'renderOption'
  | 'renderSelectedOption'
  | 'renderNoSelectableOptions'
  | 'renderEmpty'
  | 'renderLoading'
  | 'renderError'
  | 'listProps'
  | 'onChange'
> & {
  disabled?: boolean
  onKeyDown?: React.KeyboardEventHandler<HTMLDivElement>
  onBlur?: React.FocusEventHandler<HTMLDivElement>
}

export function MultiSelectField<Option, Error = unknown>({
  options,
  value,
  defaultValue,
  by,
  error,
  placeholder,
  renderValue,
  renderOption,
  renderSelectedOption,
  renderNoSelectableOptions,
  renderEmpty,
  renderLoading,
  renderError,
  listProps,
  onChange,
  onKeyDown,
  onBlur,
  ...inputProps
}: MultiSelectFieldProps<Option, Error>) {
  return (
    <MultiSelectWrapper
      options={options}
      value={value}
      defaultValue={defaultValue}
      by={by}
      error={error}
      renderValue={renderValue}
      placeholder={placeholder}
      renderOption={renderOption}
      renderSelectedOption={renderSelectedOption}
      renderNoSelectableOptions={renderNoSelectableOptions}
      renderEmpty={renderEmpty}
      renderLoading={renderLoading}
      renderError={renderError}
      disabled={inputProps.disabled}
      listProps={listProps}
      onChange={onChange}
    >
      {({
        open,
        children,
        onKeyDown: multiSelectOnKeyDown,
        onBlur: multiSelectOnBlur,
      }) => (
        <SelectInput
          {...inputProps}
          open={open}
          onKeyDown={mergeEventHandlers([multiSelectOnKeyDown, onKeyDown])}
          onBlur={mergeEventHandlers([multiSelectOnBlur, onBlur])}
        >
          {children}
        </SelectInput>
      )}
    </MultiSelectWrapper>
  )
}
