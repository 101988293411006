import { Dialog } from '@reach/dialog'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { ActivityArticle } from '../../../types'
import { DialogHeaderArea } from '../../../../../shared/dialog/DialogHeaderArea'
import { HeaderLeftArea } from '../../../../../shared/header'
import { DialogAction } from '../../../../../shared/dialog/DialogAction'
import { translationsShared } from '../../../../../shared/translations/translationsShared'
import { IconClose } from '../../../../../shared/svgs'
import { DialogTitle } from '../../../../../shared/dialog/DialogTitle'
import { ContentArea } from '../../../../../shared/content'
import {
  Section,
  SectionItem,
  SectionList,
  SectionTitle,
} from '../../../../../shared/section'
import { Size } from '../../../../../shared/section/SectionItem'
import { useGetArticlesByIds } from '../../../db/article'
import { populateArticleData } from '../../../detail/populateArticleData'
import { translationsActivity } from '../../../translations/translationsActivity'

export function ActivityOverviewArticleChipDialog({
  articles,
  isOpen,
  setIsOpen,
}: {
  articles: ActivityArticle[]
  isOpen: boolean
  setIsOpen: (isExpanded: boolean) => void
}) {
  const intl = useIntl()
  const { data } = useGetArticlesByIds(
    articles.map((article) => article.articleId)
  )
  const articleDetails = populateArticleData(articles, data)
  const title = intl.formatMessage({
    id: 'activity.overview.article.dialog.title',
    defaultMessage: 'Articles',
  })
  return (
    <Dialog
      isOpen={isOpen}
      aria-label={title}
      className="mx-auto bg-white md:my-20 md:w-full md:max-w-lg md:bg-transparent"
    >
      <DialogHeaderArea>
        <HeaderLeftArea>
          <DialogAction
            label={intl.formatMessage(translationsShared.close)}
            onClick={() => setIsOpen(false)}
            className="rounded-full bg-white hover:bg-gray-300"
          >
            <IconClose className="size-7 fill-current text-gray-700" />
          </DialogAction>
        </HeaderLeftArea>
        <DialogTitle className="text-white">{title}</DialogTitle>
      </DialogHeaderArea>
      <ContentArea>
        <Section>
          <SectionTitle>
            <FormattedMessage {...translationsActivity.detailArticle} />
          </SectionTitle>
          {articleDetails && articleDetails.length > 0 ? (
            <SectionList>
              {articleDetails.map(
                (article, index) =>
                  article && (
                    <SectionItem key={index} size={Size.xs}>
                      <div className="flex-1">
                        <h2>{article.name}</h2>
                        <h3 className="break-all pt-1 text-sm">
                          {article.code}
                        </h3>
                        {article.description !== '' && (
                          <p className="pt-1 text-sm">{article.description}</p>
                        )}
                      </div>
                      <div>
                        <span>{article.count}</span>
                      </div>
                    </SectionItem>
                  )
              )}
            </SectionList>
          ) : null}
        </Section>
      </ContentArea>
    </Dialog>
  )
}
