import { ComponentType, ReactNode } from 'react'
import classNames from 'classnames'
import { IconProps } from '../svgs/types'

type Props = {
  Icon: ComponentType<IconProps>
  children: ReactNode
  onClick?: () => void
}

export function ListChipContainer({
  children,
  className,
}: {
  className?: string
  children: ReactNode
}) {
  return (
    <div className={classNames('flex flex-wrap gap-3', className)}>
      {children}
    </div>
  )
}

export function ListChip({ Icon, onClick, children }: Props) {
  return (
    <button
      className={
        'flex items-center gap-3 rounded-full bg-white px-4 py-1.5 text-left'
      }
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        if (onClick) {
          onClick()
        }
      }}
    >
      <Icon className={'size-4'} />
      <span>{children}</span>
    </button>
  )
}
