import React, { useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { FormikProps } from 'formik'
import { ContentArea } from '../../../shared/content'
import {
  Section,
  SectionItemForm,
  SectionList,
  SectionTitle,
} from '../../../shared/section'
import { SelectionInput } from '../../../shared/form/SelectionInput'
import { NOTIFICATION_TYPES_FIELDS, NotificationType } from '../types'
import { InputWithLabel } from '../../../shared/form'
import { DateTimeInput } from '../../../shared/form/DateTimeInput'
import { Checkbox } from '../../../shared/form/Checkbox'
import { translationsNotificationTypeString } from '../translations/translationsNotification'
import { NotificationAddFormFields, NotificationEditFormFields } from './types'

const GeneralEditSection: React.FC<{
  formik:
    | FormikProps<NotificationEditFormFields>
    | FormikProps<NotificationAddFormFields>
}> = ({ formik }) => {
  const intl = useIntl()

  const { values, handleChange, setFieldValue } = formik

  useEffect(() => {
    if (!NOTIFICATION_TYPES_FIELDS[values.type]?.fields.includes('time')) {
      setFieldValue('time', null)
    }
    if (!NOTIFICATION_TYPES_FIELDS[values.type]?.fields.includes('message')) {
      setFieldValue('message', '')
    }
    if (NOTIFICATION_TYPES_FIELDS[values.type]?.fields.includes('time')) {
      setFieldValue('time', new Date())
    }
  }, [values.type, setFieldValue])

  return (
    <ContentArea>
      <Section>
        <SectionTitle>
          <FormattedMessage
            id="notification.form.title.general"
            defaultMessage="General"
          />
        </SectionTitle>
        <SectionList>
          <SectionItemForm>
            <SelectionInput
              onChange={handleChange}
              name="type"
              label={intl.formatMessage({
                id: 'notification.form.type.label',
                defaultMessage: 'Type',
              })}
            >
              <option value="" disabled>
                {intl.formatMessage({
                  id: 'notification.form.type.default',
                  defaultMessage: 'Choose a type',
                })}
              </option>
              {Object.keys(NotificationType).map((key) => {
                return (
                  <option key={key} value={key}>
                    {intl.formatMessage(
                      translationsNotificationTypeString(key)
                    )}
                  </option>
                )
              })}
            </SelectionInput>
            {NOTIFICATION_TYPES_FIELDS[values.type]?.fields.includes('time') ? (
              <DateTimeInput
                name="time"
                label={intl.formatMessage({
                  id: 'notification.form.time',
                  defaultMessage: 'Due date',
                })}
              />
            ) : null}
            {NOTIFICATION_TYPES_FIELDS[values.type]?.fields.includes(
              'message'
            ) ? (
              <InputWithLabel
                component="textarea"
                name="message"
                rows={3}
                placeholder={intl.formatMessage({
                  id: 'notification.form.message.label',
                  defaultMessage: 'Message',
                })}
                label={
                  <FormattedMessage
                    id="notification.form.message.default"
                    defaultMessage="Message"
                  />
                }
              />
            ) : null}
            <Checkbox
              name="visibility.dashboard"
              label={intl.formatMessage({
                id: 'notification.form.visibilityDashboard.label',
                defaultMessage: 'Show on dashboard',
              })}
            />
          </SectionItemForm>
        </SectionList>
      </Section>
    </ContentArea>
  )
}

export { GeneralEditSection }
