import React from 'react'
import { Form, Formik } from 'formik'
import { useNavigate } from 'react-router-dom'
import { formatDateForAPI } from '../../../shared/utils/apiUtils'
import { SubmitButton } from '../../../shared/form/button/SubmitButton'
import { CancelButton } from '../../../shared/form/button/CancelButton'
import { SectionActions } from '../../../shared/section/SectionActions'
import { NotificationAddFormFields } from './types'
import { GeneralEditSection } from './GeneralEditSection'
import { useGetCreateNotificationValidationSchema } from './notificationFormValidation'
import { AssignNotificationAddSection } from './AssignNotificationAddSection'

const NotificationAddForm: React.FC<{
  initialValues: NotificationAddFormFields
  onSubmit: (values: NotificationAddFormFields) => void
}> = ({ initialValues, onSubmit }) => {
  const navigate = useNavigate()

  const schema = useGetCreateNotificationValidationSchema()

  const prepareAndSubmit = (values: NotificationAddFormFields) => {
    onSubmit({
      ...values,
      time: values.time ? formatDateForAPI(values.time) : null,
    })
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values: NotificationAddFormFields) => prepareAndSubmit(values)}
      validationSchema={schema}
      validateOnChange={false}
    >
      {(props) => (
        <Form>
          <GeneralEditSection formik={props} />
          <AssignNotificationAddSection formik={props} />
          <SectionActions>
            <CancelButton onClick={() => navigate(-1)} />
            <SubmitButton />
          </SectionActions>
        </Form>
      )}
    </Formik>
  )
}

export { NotificationAddForm }
