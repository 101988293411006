import IconAttachFile from '@aboutbits/react-material-icons/dist/IconAttachFile'
import { FormattedMessage } from 'react-intl'
import { useState } from 'react'
import { ListChip } from '../../../../shared/listChip/ListChip'
import { FileReference } from '../../../file/types'
import { ActivityOverviewChipFileDialog } from './dialog/ActivityOverviewChipFileDialog'

export function ActivityOverviewFileChip({
  files,
}: {
  files: FileReference[]
}) {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  return (
    <>
      <ListChip Icon={IconAttachFile} onClick={() => setIsOpen(true)}>
        <>
          <FormattedMessage
            id="activity.overview.list.item.chips.files"
            defaultMessage="Files"
          />
          {` (${files.length})`}
        </>
      </ListChip>
      <ActivityOverviewChipFileDialog
        files={files}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </>
  )
}
