import React, { ComponentType } from 'react'
import { IconProps } from '@aboutbits/react-material-icons/dist/types'
import classNames from 'classnames'

const SectionItemAction: React.FC<{
  icon: ComponentType<IconProps>
  label: string
  disabled?: boolean
  className?: string
  onClick: () => void
}> = ({ icon: Icon, label, disabled = false, className, onClick }) => {
  return (
    <div>
      <button
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          onClick()
        }}
        aria-label={label}
        disabled={disabled}
        className={classNames(
          'text-black hover:text-gray-700 focus:text-gray-700 align-text-top',
          className
        )}
      >
        <Icon className="size-6 fill-current" title={label} />
      </button>
    </div>
  )
}

export { SectionItemAction }
