import React, { useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import debounce from 'lodash.debounce'
import { IconArrowBack, IconClose } from '../svgs'
import { translationsShared } from '../translations/translationsShared'
import { HeaderArea } from './HeaderArea'
import { HeaderLargeAction } from './HeaderLargeAction'
import { HeaderSmallAction } from './HeaderSmallAction'
import { HeaderLeftArea, HeaderRightArea } from './index'

const HeaderSearch: React.FC<{
  text: string
  setText: (string: string) => void
  stopSearch: () => void
  clearSearch: () => void
}> = ({ text, setText, stopSearch, clearSearch }) => {
  const intl = useIntl()
  const [searchText, setSearchText] = useState(text)
  const searchInput = useRef<HTMLInputElement>(null)

  const debouncedSearch = React.useRef(
    debounce(
      (criteria) => {
        setText(criteria)
      },
      300,
      { trailing: true }
    )
  ).current

  useEffect(() => {
    if (searchInput.current !== null) {
      searchInput.current.focus()
    }
  }, [])

  useEffect(() => {
    return () => {
      debouncedSearch.cancel()
    }
  }, [debouncedSearch])

  useEffect(() => {
    debouncedSearch(searchText)
  }, [debouncedSearch, searchText])

  return (
    <HeaderArea
      navigation={
        <HeaderLeftArea className="md:hidden">
          <HeaderLargeAction
            icon={IconArrowBack}
            label={intl.formatMessage(translationsShared.searchBack)}
            onClick={stopSearch}
          />
        </HeaderLeftArea>
      }
    >
      <div className="flex flex-1 rounded-full bg-gray-300 px-3 py-1 md:bg-transparent md:p-0">
        <input
          ref={searchInput}
          value={searchText}
          onChange={(ev: React.ChangeEvent<HTMLInputElement>): void => {
            setSearchText(ev.target.value)
          }}
          placeholder={intl.formatMessage(translationsShared.searchPlaceholder)}
          className="w-full flex-1 border-none bg-transparent text-base text-black outline-none placeholder:text-gray-500 md:text-3xl"
        />
        <button
          className="hover:text-gray-700 focus:text-gray-700 md:hidden"
          aria-label={intl.formatMessage(translationsShared.searchClear)}
          onClick={clearSearch}
        >
          <IconClose className="size-4 fill-current" />
        </button>
      </div>
      <HeaderRightArea className="hidden md:block">
        <HeaderSmallAction
          icon={IconClose}
          label={intl.formatMessage(translationsShared.searchBack)}
          onClick={stopSearch}
        />
      </HeaderRightArea>
    </HeaderArea>
  )
}

export { HeaderSearch }
