import { useIntl } from 'react-intl'
import React, { useState } from 'react'
import { Project } from '../types'
import {
  FloatingMenu,
  FloatingMenuButtonLink,
} from '../../../shared/floatingMenu/FloatingMenu'
import {
  IconEdit,
  IconFormatListBulleted,
  IconNotifications,
} from '../../../shared/svgs'
import { translationsNotification } from '../../notification/translations/translationsNotification'
import { translationsShared } from '../../../shared/translations/translationsShared'
import { FloatingButton } from '../../../shared/floatingArea'
import { ActivityAddDialog } from '../../activity/add/ActivityAddDialog'

type Props = {
  project: Project
  canAddActivity: boolean
}

export function ProjectDetailsFloatingMenu({ project, canAddActivity }: Props) {
  const intl = useIntl()

  const [showDialog, setShowDialog] = useState<boolean>(false)

  return (
    <>
      <FloatingMenu>
        {canAddActivity && (
          <FloatingButton
            className={'p-3'}
            onClick={() => {
              setShowDialog(true)
            }}
          >
            <IconFormatListBulleted
              className={'size-4'}
              title={intl.formatMessage({
                id: 'project.detail.floatingMenu.addChildActivity',
                defaultMessage: 'Add child activity',
              })}
            />
          </FloatingButton>
        )}
        <FloatingMenuButtonLink
          to={`/projects/${project.id}/notifications/add`}
        >
          <IconNotifications
            className="size-4 fill-current"
            title={intl.formatMessage(translationsNotification.resourceAdd)}
          />
        </FloatingMenuButtonLink>
        <FloatingMenuButtonLink to={`/projects/${project.id}/edit`}>
          <IconEdit
            className="size-4 fill-current"
            title={intl.formatMessage(translationsShared.edit)}
          />
        </FloatingMenuButtonLink>
      </FloatingMenu>
      <ActivityAddDialog
        isOpen={showDialog}
        onDismis={() => setShowDialog(false)}
        query={{
          project: project.id,
          address: project.addressId ?? undefined,
        }}
      />
    </>
  )
}
