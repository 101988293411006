import { useCallback, useEffect, useRef } from 'react'
import autosize from 'autosize'

export function useAutoSizeTextArea(options?: { enabled?: boolean }) {
  const isEnabled = options?.enabled ?? false

  const elementRef = useRef<HTMLTextAreaElement | null>(null)

  const updateElement = useCallback(
    (element: HTMLTextAreaElement | null) => {
      if (isEnabled && element && element !== elementRef.current) {
        elementRef.current = element
        autosize(element)
      }
    },
    [isEnabled]
  )

  useEffect(() => {
    return () => {
      if (isEnabled && elementRef.current) {
        autosize.destroy(elementRef.current)
      }
    }
  }, [isEnabled])

  return { updateElement }
}
