import React, { ComponentType, useCallback, useContext, useState } from 'react'
import classnames from 'classnames'
import { v4 as uuid } from 'uuid'
import { format } from 'date-fns'

import { useIntl } from 'react-intl'
import { LocationContext } from '@aboutbits/react-toolbox'
import {
  IconAlarm,
  IconPause,
  IconPlayArrow,
  IconStop,
} from '../../../shared/svgs'
import { IconProps } from '../../../shared/svgs/types'
import { syncTimeTracking } from '../sync'
import { RecordType, TimeTrackingRecord } from '../types'
import { SelectProjectDialog } from '../shared/SelectProjectDialog'
import { SelectCostCenterDialog } from '../shared/SelectCostCenterDialog'
import { createTimeTrackingRecord } from '../db/records'
import { addUpsertBufferEntry } from '../db/buffer'
import { AuthContext } from '../../../auth/AuthContext'
import { ModuleAccessControl } from '../../../auth/types'
import { ProjectVisibility } from '../../project/types'
import { SelectCostCenterGroupDialog } from '../shared/SelectCostCenterGroupDialog'
import { DismissType } from '../../../shared/dialog/types'
import { translationsTimetracking } from '../translations/translationsTimeTracking'
import styles from './TimeTrackingWidgetAction.module.css'

export enum Variant {
  inline,
  stacked,
}

type CustomActionProps = {
  variant?: Variant
}

async function createRecord(
  type: RecordType,
  projectId: string | null = null,
  costCenterId: string | null = null,
  location: GeolocationPosition | null,
  modules?: Array<ModuleAccessControl>
) {
  const time =
    type === RecordType.WORK_START ? new Date().setSeconds(0) : new Date()

  const timeTrackingEntry: TimeTrackingRecord = {
    id: uuid(),
    time: format(time, "yyyy-MM-dd'T'HH:mm:ss"),
    location: {
      longitude: location == null ? null : location.coords.longitude,
      latitude: location == null ? null : location.coords.latitude,
    },
    type,
    description: null,
    projectId: projectId,
    costCenterId: costCenterId,
  }

  await createTimeTrackingRecord(timeTrackingEntry)
  await addUpsertBufferEntry(timeTrackingEntry)

  if (modules !== undefined) {
    syncTimeTracking(modules)
  }
}

const variantButtonStyle: Record<Variant, string> = {
  [Variant.inline]: 'flex flex-row justify-center',
  [Variant.stacked]: 'flex flex-col',
}

const variantIconStyle: Record<Variant, string> = {
  [Variant.inline]: 'm-2 p-2',
  [Variant.stacked]: 'm-4 p-4 lg:p-6',
}

const variantTextStyle: Record<Variant, string> = {
  [Variant.inline]: '',
  [Variant.stacked]: 'mt-4 md:h-11',
}

const TimeTrackingWidgetAction: React.FC<{
  Icon: ComponentType<IconProps>
  text: string
  label: string
  onClick: () => void
  variant?: Variant
}> = ({ Icon, text, label, onClick, variant = Variant.stacked }) => {
  return (
    <button
      className={classnames(
        variantButtonStyle[variant],
        'flex-1 items-center py-6 hover:shadow-xl focus:outline-none md:max-w-1/3 md:flex-auto md:rounded-lg md:bg-white md:p-7 md:shadow-lg lg:px-9'
      )}
      aria-label={label}
      onClick={onClick}
    >
      <div className="rounded-full border border-primary-500">
        <div
          className={classnames(
            styles['shadow-icon'],
            variantIconStyle[variant],
            'overflow-hidden rounded-full bg-primary-500'
          )}
        >
          <Icon className="size-6" />
        </div>
      </div>
      <div
        className={classnames(
          variantTextStyle[variant],
          'self-center px-4 text-md text-white md:w-26 md:px-0 md:text-gray-700'
        )}
      >
        {text}
      </div>
    </button>
  )
}

const TimeTrackingWidgetActionStart: React.FC<CustomActionProps> = (props) => {
  const { data: moduleData } = useContext(AuthContext)
  const { location } = useContext(LocationContext)
  const intl = useIntl()

  const start = () => {
    createRecord(
      RecordType.WORK_START,
      null,
      null,
      location,
      moduleData?.modules
    )
  }
  return (
    <TimeTrackingWidgetAction
      Icon={IconPlayArrow}
      text={intl.formatMessage({
        id: 'timeTracking.action.work_start.text',
        defaultMessage: 'Start',
      })}
      label={intl.formatMessage(translationsTimetracking.actionWorkStartLabel)}
      onClick={start}
      {...props}
    />
  )
}

const TimeTrackingWidgetActionStop: React.FC<CustomActionProps> = (props) => {
  const { data: moduleData } = useContext(AuthContext)
  const { location } = useContext(LocationContext)
  const intl = useIntl()

  const stop = () => {
    createRecord(
      RecordType.WORK_STOP,
      null,
      null,
      location,
      moduleData?.modules
    )
  }
  return (
    <TimeTrackingWidgetAction
      Icon={IconStop}
      text={intl.formatMessage({
        id: 'timeTracking.action.work_stop.text',
        defaultMessage: 'End',
      })}
      label={intl.formatMessage(translationsTimetracking.actionWorkStopLable)}
      onClick={stop}
      {...props}
    />
  )
}

const TimeTrackingWidgetActionPause: React.FC<CustomActionProps> = (props) => {
  const { data: moduleData } = useContext(AuthContext)
  const { location } = useContext(LocationContext)
  const intl = useIntl()

  const pause = () => {
    createRecord(
      RecordType.WORK_PAUSE,
      null,
      null,
      location,
      moduleData?.modules
    )
  }
  return (
    <TimeTrackingWidgetAction
      Icon={IconPause}
      text={intl.formatMessage({
        id: 'timeTracking.action.work_pause.text',
        defaultMessage: 'Pause',
      })}
      label={intl.formatMessage(translationsTimetracking.actionWorkPauseLabel)}
      onClick={pause}
      {...props}
    />
  )
}

enum SelectionState {
  closed,
  step_project,
  step_cost_center_group,
  step_cost_center,
}

const TimeTrackingWidgetActionProjectCostCenter: React.FC<CustomActionProps> = (
  props
) => {
  const { data: moduleData } = useContext(AuthContext)
  const { location } = useContext(LocationContext)
  const [selectionState, setSelectionState] = useState<SelectionState>(
    SelectionState.closed
  )
  const [projectId, setProjectId] = useState<string | null>(null)
  const [costCenterGroupId, setCostCenterGroupId] = useState<string | null>(
    null
  )

  const onConfirmProject = useCallback((projectId: string): void => {
    setProjectId(projectId)
    setSelectionState(SelectionState.step_cost_center_group)
  }, [])

  const onDismissProject = useCallback((): void => {
    setSelectionState(SelectionState.closed)
  }, [])

  const onConfirmCostCenterGroup = useCallback(
    (costCenterGroupId: string | null): void => {
      setCostCenterGroupId(costCenterGroupId)
      setSelectionState(SelectionState.step_cost_center)
    },
    []
  )

  const onDismissCostCenterGroup = useCallback((): void => {
    setSelectionState(SelectionState.step_project)
  }, [])

  const onConfirmCostCenter = useCallback(
    async (costCenterId: string): Promise<void> => {
      try {
        await createRecord(
          RecordType.PROJECT_COSTCENTER,
          projectId,
          costCenterId,
          location,
          moduleData?.modules
        )
      } finally {
        setSelectionState(SelectionState.closed)
      }
    },
    [location, moduleData?.modules, projectId]
  )

  const onDismissCostCenter = useCallback((): void => {
    setSelectionState(SelectionState.step_cost_center_group)
  }, [])
  const intl = useIntl()
  return (
    <>
      <TimeTrackingWidgetAction
        Icon={IconAlarm}
        text={intl.formatMessage({
          id: 'timeTracking.action.work_project_costCenter.text',
          defaultMessage: 'Project & Cost Center',
        })}
        label={intl.formatMessage({
          id: 'timeTracking.action.work_project_costCenter.label',
          defaultMessage: 'Project & Cost Center',
        })}
        onClick={() => {
          setSelectionState(SelectionState.step_project)
        }}
        {...props}
      />
      <SelectProjectDialog
        projectVisibility={ProjectVisibility.timeTracking}
        isOpen={selectionState === SelectionState.step_project}
        dismissType={DismissType.close}
        onDismiss={onDismissProject}
        onConfirm={onConfirmProject}
      />
      <SelectCostCenterGroupDialog
        isOpen={selectionState === SelectionState.step_cost_center_group}
        dismissType={DismissType.back}
        onDismiss={onDismissCostCenterGroup}
        onConfirm={onConfirmCostCenterGroup}
        onConfirmFavorite={onConfirmCostCenter}
      />
      <SelectCostCenterDialog
        isOpen={selectionState === SelectionState.step_cost_center}
        dismissType={DismissType.back}
        costCenterGroupId={costCenterGroupId}
        onDismiss={onDismissCostCenter}
        onConfirm={onConfirmCostCenter}
      />
    </>
  )
}

export {
  TimeTrackingWidgetAction,
  TimeTrackingWidgetActionStart,
  TimeTrackingWidgetActionStop,
  TimeTrackingWidgetActionPause,
  TimeTrackingWidgetActionProjectCostCenter,
}
