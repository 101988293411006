import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  NotificationAddFormFields,
  NotificationAssignmentType,
} from '../forms/types'
import { extractErrorMessage } from '../../../shared/utils/apiUtils'
import { Notification } from '../types'
import { ResourceType } from '../../../shared/utils/resourceTypes'
import { addNotification } from '../db/notifications'
import { AuthContext } from '../../../auth/AuthContext'
import { AppContext } from '../../../app/AppContext'
import { Group } from '../../activity/types'
import { NotificationAddForm } from '../forms/NotificationAddForm'
import { createNotification } from '../api'

type Props = {
  resourceType: ResourceType
  resourceId: string
  activityGroup?: Group
}

const NotificationAddSection: React.FC<Props> = ({
  resourceType,
  resourceId,
  activityGroup,
}) => {
  const navigate = useNavigate()
  const { addAlertErrorMessage } = useContext(AppContext)

  const initialValues = useGetInitialValues(
    resourceType,
    resourceId,
    activityGroup
  )

  const onSubmit = async (value: NotificationAddFormFields): Promise<void> => {
    try {
      const { users, addressGroups, ...baseNotification } = value

      switch (value.assignmentType) {
        case NotificationAssignmentType.none:
          const createdNotification = await createNotification({
            ...baseNotification,
            userId: null,
            addressGroupId: null,
          } as Notification)
          await addNotification(createdNotification)
          break
        case NotificationAssignmentType.user:
          for (const user of value.users) {
            const createdNotification = await createNotification({
              ...baseNotification,
              userId: user.id,
              addressGroupId: null,
            } as Notification)
            await addNotification(createdNotification)
          }
          break
        case NotificationAssignmentType.addressGroup:
          for (const addressGroup of value.addressGroups) {
            const createdNotification = await createNotification({
              ...baseNotification,
              userId: null,
              addressGroupId: addressGroup.id,
            } as Notification)
            await addNotification(createdNotification)
          }
          break
      }

      navigate(-1)
    } catch (error: any) {
      addAlertErrorMessage(extractErrorMessage(error))
    }
  }

  return (
    <NotificationAddForm initialValues={initialValues} onSubmit={onSubmit} />
  )
}

const useGetInitialValues = (
  resourceType: ResourceType,
  resourceId: string,
  activityGroup?: Group
): NotificationAddFormFields => {
  const auth = useContext(AuthContext)
  const users = auth.data?.user
    ? [{ id: auth.data.user.id, name: auth.data.user.name }]
    : []

  const defaultType = activityGroup?.defaultNotificationType ?? ''

  return {
    id: '',
    sort: '-',
    type: defaultType,
    users: users,
    addressGroups: [],
    referencedResourceType: resourceType,
    referencedResourceId: resourceId,
    assignmentType: NotificationAssignmentType.user,
    time: null,
    message: '',
    archived: false,
    visibility: {
      dashboard: false,
    },
    createdAt: '',
    updatedAt: '',
  }
}

export { NotificationAddSection }
