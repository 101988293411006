import React, { useContext, useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { FormikProps } from 'formik'
import { ContentArea } from '../../../shared/content'
import {
  Section,
  SectionItemForm,
  SectionList,
  SectionTitle,
} from '../../../shared/section'
import { RadioGroup, RadioGroupRadio } from '../../../shared/form/radioGroup'
import { AuthContext } from '../../../auth/AuthContext'
import { useGetAllUsers } from '../../user/db/users'
import { useGetAddressGroupsForNotifications } from '../../address/db/addressGroups'
import { NOTIFICATION_TYPES_FIELDS } from '../types'
import { MultiSelectFormField } from '../../../shared/form/MultiSelectFormField'
import { NotificationAddFormFields, NotificationAssignmentType } from './types'

const AssignNotificationAddSection: React.FC<{
  formik: FormikProps<NotificationAddFormFields>
}> = ({ formik }) => {
  const intl = useIntl()

  const { values, setFieldValue } = formik

  const auth = useContext(AuthContext)

  const addressGroups = useGetAddressGroupsForNotifications()
  const users = useGetAllUsers()

  useEffect(() => {
    if (
      values.assignmentType === NotificationAssignmentType.none &&
      !NOTIFICATION_TYPES_FIELDS[values.type]?.enableAssignmentTypeNone
    ) {
      const user = auth.data?.user
        ? [{ id: auth.data.user.id, name: auth.data.user.name }]
        : []

      setFieldValue('assignmentType', NotificationAssignmentType.user)
      setFieldValue('users', user)
    }
  }, [values.assignmentType, values.type, setFieldValue, auth.data?.user])

  useEffect(() => {
    if (values.assignmentType !== NotificationAssignmentType.user) {
      setFieldValue('users', [])
    }
    if (values.assignmentType !== NotificationAssignmentType.addressGroup) {
      setFieldValue('addressGroups', [])
    }
  }, [values.assignmentType, setFieldValue])

  return (
    <ContentArea>
      <Section>
        <SectionTitle>
          <FormattedMessage
            id="notification.form.title.assign"
            defaultMessage="Assign"
          />
        </SectionTitle>
        <SectionList>
          <SectionItemForm>
            <RadioGroup name="assignmentType">
              {NOTIFICATION_TYPES_FIELDS[values.type]
                ?.enableAssignmentTypeNone ? (
                <RadioGroupRadio
                  value={NotificationAssignmentType.none}
                  label={intl.formatMessage({
                    id: `notification.form.assignmentTypes.none`,
                    defaultMessage: 'None',
                  })}
                />
              ) : null}
              <RadioGroupRadio
                value={NotificationAssignmentType.user}
                label={intl.formatMessage({
                  id: `notification.form.assignmentTypes.user`,
                  defaultMessage: 'User',
                })}
              />
              <RadioGroupRadio
                value={NotificationAssignmentType.addressGroup}
                label={intl.formatMessage({
                  id: `notification.form.assignmentTypes.addressGroup`,
                  defaultMessage: 'Group',
                })}
              />
            </RadioGroup>
            {values.assignmentType === NotificationAssignmentType.user ? (
              <MultiSelectFormField
                label={intl.formatMessage({
                  id: 'notification.form.users.label',
                  defaultMessage: 'Users',
                })}
                options={users.data?.map((user) => ({
                  id: user.id,
                  name: user.name,
                }))}
                name={'users'}
                placeholder={intl.formatMessage({
                  id: 'notification.form.userIds.label',
                  defaultMessage: 'Select users',
                })}
                by={'id'}
                renderValue={(option) => option.name}
              />
            ) : values.assignmentType ===
              NotificationAssignmentType.addressGroup ? (
              <MultiSelectFormField
                label={intl.formatMessage({
                  id: 'notification.form.groups.label',
                  defaultMessage: 'Groups',
                })}
                options={addressGroups.data?.map((group) => ({
                  id: group.id,
                  name: group.name,
                }))}
                name={'addressGroups'}
                placeholder={intl.formatMessage({
                  id: 'notification.form.groupIds.label',
                  defaultMessage: 'Select groups',
                })}
                by={'id'}
                renderValue={(option) => option.name}
              />
            ) : null}
          </SectionItemForm>
        </SectionList>
      </Section>
    </ContentArea>
  )
}

export { AssignNotificationAddSection }
