import React, { ComponentType } from 'react'
import { IconLock } from '../../shared/svgs'
import { IconProps } from '../../shared/svgs/types'

export interface MenuLockedProps {
  text: string
  icon: ComponentType<IconProps>
}

export const MenuLocked: React.FC<MenuLockedProps> = ({ text, icon: Icon }) => {
  return (
    <div className="flex flex-row items-center px-4 py-1 text-sm">
      <Icon className="mr-4 size-6 fill-current" />
      <div className="flex-1">{text}</div>
      <div className="rounded-full bg-black p-1 text-gray-300">
        <IconLock className="size-4 fill-current" />
      </div>
    </div>
  )
}
