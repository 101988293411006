import { FormattedMessage } from 'react-intl'
import { IconClose } from '../svgs'
import { translationsShared } from '../translations/translationsShared'
import { ChipButton } from './ChipButton'

type ClearChipButtonProps = {
  onClick: () => void
}

export const ClearChipButton: React.FC<ClearChipButtonProps> = ({
  onClick,
}) => {
  return (
    <ChipButton onClick={onClick} selected={false}>
      <div className="flex items-center gap-1">
        <IconClose className="m-0 size-3" />
        <FormattedMessage {...translationsShared.filterButtonClear} />
      </div>
    </ChipButton>
  )
}
