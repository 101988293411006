import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Section, SectionList, SectionTitle } from '../../../shared/section'

const MessageSection: React.FC<{
  message: string | null
}> = ({ message }) => {
  return message ? (
    <Section>
      <SectionTitle>
        <FormattedMessage
          id="notification.detail.message"
          defaultMessage="Message"
        />
      </SectionTitle>
      <SectionList>
        <div className="whitespace-pre-wrap px-3 py-5 md:p-5">{message}</div>
      </SectionList>
    </Section>
  ) : null
}

export { MessageSection }
