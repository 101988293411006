import { PropsWithChildren } from 'react'
import classNames from 'classnames'

export type SelectEmptyPanelProps = PropsWithChildren<{
  className?: string
}>

export function SelectEmptyPanel({
  className,
  children,
}: SelectEmptyPanelProps) {
  return (
    <div className={classNames('p-12 text-center', className)}>{children}</div>
  )
}
