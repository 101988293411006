import React, { ComponentType } from 'react'
import { IconProps } from '@aboutbits/react-material-icons/dist/types'
import { HeaderRightArea } from '../header'
import { DialogAction } from './DialogAction'

type DialogGenericActionProps = {
  icon: ComponentType<IconProps>
  label: string
  onClick: () => void
}

const DialogGenericAction: React.FC<DialogGenericActionProps> = ({
  icon: Icon,
  label,
  onClick,
}) => {
  return (
    <HeaderRightArea className="self-end md:self-start">
      <DialogAction
        label={label}
        onClick={onClick}
        className="-mb-4 -mr-4 rounded-full bg-gray-700 text-white hover:bg-gray-600 md:mb-0 md:mr-0"
      >
        <Icon className="size-6 fill-current md:size-8" title={label} />
      </DialogAction>
    </HeaderRightArea>
  )
}

export { DialogGenericAction }
