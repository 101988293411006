import React, { ReactElement } from 'react'
import { useIntl } from 'react-intl'
import { Dialog } from '@reach/dialog'
import { AsyncView } from '@aboutbits/react-toolbox'
import { useSearchParams } from 'react-router-dom'
import classNames from 'classnames'
import { useSearchAndPaginationInMemory } from '../../../../shared/pagination/inMemoryPagination'
import { useGetAddresses } from '../../../address/db/addresses'
import { DialogSearchHeader } from '../../../../shared/dialog/DialogSearchHeader'
import { ContentArea } from '../../../../shared/content'
import {
  Section,
  SectionItem,
  SectionList,
  SectionPaginationInMemory,
  SectionTitle,
} from '../../../../shared/section'
import { Empty } from '../../../../shared/empty'
import { IconKeyboardArrowRight, IconStore } from '../../../../shared/svgs'
import { PaginatedData } from '../../../../db/types'
import { Address } from '../../../address/types'
import { PaginationActions } from '../../../../shared/pagination/types'
import { DialogSelectionListItemWithText } from '../../../../shared/dialog/selection/DialogSelectionListItemWithText'
import { Size } from '../../../../shared/section/SectionItem'
import { translationsAddress } from '../../../address/translations/translationsAddress'
import { translationsActivity } from '../../translations/translationsActivity'

type Props = {
  isOpen: boolean
  onDismiss: () => void
}

function ActivityOverviewAddressFilter({
  isOpen,
  onDismiss,
}: Props): ReactElement {
  const intl = useIntl()
  const [filterParams, setFilterParams] = useSearchParams()
  const { page, size, search, searchActions, paginationActions } =
    useSearchAndPaginationInMemory()

  const searching = search !== ''

  const { data } = useGetAddresses(search, page, size)

  const selectAddress = (addressId: string): void => {
    if (addressId === '') {
      filterParams.delete('address')
      setFilterParams(filterParams)
    } else {
      setFilterParams({ address: addressId })
    }

    onDismiss()
  }

  return (
    <Dialog
      isOpen={isOpen}
      aria-label={intl.formatMessage(
        translationsActivity.overviewFilterAddressTitle
      )}
      className="mx-auto bg-white md:my-20 md:w-full md:max-w-lg md:bg-transparent"
      onDismiss={onDismiss}
    >
      <DialogSearchHeader
        onDismiss={onDismiss}
        title={intl.formatMessage(
          translationsActivity.overviewFilterAddressTitle
        )}
        searchLabel={intl.formatMessage(
          translationsActivity.overviewFilterAddressSearch
        )}
        search={search}
        searchActions={searchActions}
      />
      <ContentArea>
        <AsyncView
          data={data}
          renderSuccess={(data) =>
            data && (
              <AddressDialogList
                searching={searching}
                onSelect={selectAddress}
                selectedAddress={filterParams.get('address')}
                paginationActions={paginationActions}
                data={data}
              />
            )
          }
        />
      </ContentArea>
    </Dialog>
  )
}

function AddressDialogList({
  searching,
  data,
  onSelect,
  selectedAddress,
  paginationActions,
}: {
  searching: boolean
  data: PaginatedData<Address>
  selectedAddress: string | null
  onSelect: (addressId: string) => void
} & PaginationActions) {
  const intl = useIntl()

  const title = searching
    ? intl.formatMessage(translationsActivity.addressDialogSearchResult)
    : intl.formatMessage(translationsAddress.overviewList)

  const empty = searching
    ? intl.formatMessage(translationsActivity.addressDialogSearchEmpty)
    : intl.formatMessage(translationsActivity.addressDialogListEmpty)

  return (
    <>
      <ClearFilter onSelect={onSelect} />
      <Section>
        <SectionTitle>{title}</SectionTitle>
        <SectionList>
          {data.items.map((address) => (
            <AddressItem
              key={address.id}
              address={address}
              selected={
                selectedAddress != null && address.id === selectedAddress
              }
              onSelect={onSelect}
            />
          ))}
          {data.items.length === 0 && <Empty icon={IconStore} text={empty} />}
        </SectionList>
        <SectionPaginationInMemory
          total={data.total}
          size={data.size}
          page={data.page}
          paginationActions={paginationActions}
        />
      </Section>
    </>
  )
}

function ClearFilter({
  onSelect,
}: {
  onSelect: (addressId: string) => void
}): ReactElement {
  const intl = useIntl()

  return (
    <Section>
      <SectionList>
        <DialogSelectionListItemWithText
          text={intl.formatMessage({
            id: 'address.overview.filter.showAll',
            defaultMessage: 'Show all addresses',
          })}
          itemId=""
          onSelect={onSelect}
        />
      </SectionList>
    </Section>
  )
}

function AddressItem({
  address,
  selected,
  onSelect,
}: {
  address: Address
  selected: boolean
  onSelect: (addressId: string) => void
}): ReactElement {
  return (
    <button
      onClick={() => onSelect(address.id)}
      className={classNames(
        'block w-full focus:outline-none',
        selected ? 'bg-primary-500' : 'hover:bg-gray-500'
      )}
    >
      <SectionItem size={Size.sm}>
        <div className="flex-1 text-left">{address.name}</div>
        <IconKeyboardArrowRight className="size-5" />
      </SectionItem>
    </button>
  )
}

export { ActivityOverviewAddressFilter }
