import { PropsWithChildren } from 'react'
import classNames from 'classnames'

export interface SelectOptionState {
  active: boolean
  disabled: boolean
  selected: boolean
}

export type SelectOptionProps = PropsWithChildren<{
  state?: SelectOptionState
  className?: string
}>

export function SelectOption({
  state,
  className,
  children,
}: SelectOptionProps) {
  return (
    <div
      className={classNames(
        'relative cursor-default select-none px-6 py-3',
        state?.active &&
          'bg-gray-500 group-first/option:-mt-2 group-first/option:!pt-5 group-last/option:-mb-2 group-last/option:!pb-5',
        className
      )}
    >
      {children}
    </div>
  )
}
