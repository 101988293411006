import React from 'react'
import { Button } from '../button'
import { Variant } from '../button/Button'
import { IconAddCircleOutline } from '../svgs'

const SectionListAddButton: React.FC<{
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  text: string
}> = ({ text, onClick }) => {
  return (
    <div className={'flex w-full items-center justify-center'}>
      <Button
        variant={Variant.secondary}
        onClick={onClick}
        className="flex items-center justify-center"
      >
        <IconAddCircleOutline height="24" />
        <span className="ml-1">{text}</span>
      </Button>
    </div>
  )
}

export { SectionListAddButton }
