import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import IconAdd from '@aboutbits/react-material-icons/dist/IconAdd'
import { ContentArea } from '../../../shared/content'
import { ButtonInternalLink } from '../../../shared/button'
import { Variant } from '../../../shared/button/Button'
import { SectionActions } from '../../../shared/section/SectionActions'
import { FloatingArea, FloatingLinkButton } from '../../../shared/floatingArea'
import {
  getUsedCategories,
  populateUserActivityCategories,
} from '../../settings/populate'
import { Activity, Group } from '../types'
import { Help } from '../../../shared/help/Help'
import { useHasModulePermission } from '../../../auth/hooks'
import { Module, Permission } from '../../../auth/types'
import { translationsActivity } from '../translations/translationsActivity'
import { useGetActivityCategories, useGetAllActivities } from '../db/activities'
import { ClearChipButton } from '../../../shared/filter/ClearChipButton'
import { ChipButton } from '../../../shared/filter/ChipButton'
import { ChipsArea } from '../../../shared/filter/ChipsArea'
import { ActivityOverviewViewStage } from './ActivityOverviewViewStage'

const ActivityOverviewContent: React.FC<{
  searching: boolean
  search: string
  page: number
  size: number
  filter?: (item: Activity) => boolean
  activityGroup: Group
}> = ({ searching, search, page, size, activityGroup, filter }) => {
  const intl = useIntl()
  const { data: activityCategories } = useGetActivityCategories()
  const { data: allActivities } = useGetAllActivities(
    filter,
    activityGroup.listSortDirection
  )
  const availableCategories = populateUserActivityCategories(
    activityGroup,
    activityCategories
  )
  const usedCategories = getUsedCategories(
    availableCategories,
    allActivities
  ).sort((a, b) => a.sort.localeCompare(b.sort))

  const [selectedCategoryIds, setSelectedCategoryIds] = React.useState<
    string[]
  >([])

  const extendedFilter = (item: Activity) => {
    if (filter && !filter(item)) {
      return false
    }
    if (selectedCategoryIds.length === 0) {
      return true
    }
    if (item.activityCategoryId) {
      return selectedCategoryIds.includes(item.activityCategoryId)
    }
    return false
  }

  const hasCreatePermissions = useHasModulePermission(
    Module.activities,
    Permission.create
  )

  return (
    <div>
      <ChipsArea>
        {usedCategories.map((category) => (
          <ChipButton
            key={category.id}
            onClick={() => {
              if (selectedCategoryIds.includes(category.id)) {
                setSelectedCategoryIds(
                  selectedCategoryIds.filter(
                    (selectedId) => selectedId !== category.id
                  )
                )
              } else {
                setSelectedCategoryIds([...selectedCategoryIds, category.id])
              }
            }}
            selected={selectedCategoryIds.includes(category.id)}
          >
            {category.name}
          </ChipButton>
        ))}
        {selectedCategoryIds.length > 0 && (
          <ClearChipButton
            onClick={() => {
              setSelectedCategoryIds([])
            }}
          />
        )}
      </ChipsArea>
      <ContentArea>
        <ActivityOverviewViewStage
          searching={searching}
          search={search}
          page={page}
          size={size}
          activityGroup={activityGroup}
          filter={extendedFilter}
        />
        {hasCreatePermissions &&
          !activityGroup.listConfig.activityCreateHidden && (
            <SectionActions>
              <ButtonInternalLink
                to={`/activity-groups/${activityGroup.id}/activities/add`}
                variant={Variant.primary}
              >
                <span className="flex h-full items-center justify-center px-6">
                  <FormattedMessage {...translationsActivity.addTitle} />
                </span>
              </ButtonInternalLink>
            </SectionActions>
          )}

        <Help href="https://camid.eu" />
      </ContentArea>
      {hasCreatePermissions &&
        !activityGroup.listConfig.activityCreateHidden && (
          <FloatingArea>
            <FloatingLinkButton
              to={`/activity-groups/${activityGroup.id}/activities/add`}
            >
              <IconAdd
                className="size-6 fill-current"
                title={intl.formatMessage(translationsActivity.addTitle)}
              />
            </FloatingLinkButton>
          </FloatingArea>
        )}
    </div>
  )
}

export { ActivityOverviewContent }
