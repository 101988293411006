import { FormattedMessage } from 'react-intl'
import React from 'react'
import classNames from 'classnames'
import { IconCopyright } from '../../shared/svgs'
import { translationsShared } from '../../shared/translations/translationsShared'

type FooterProps = {
  className?: string
  setLocale?: React.Dispatch<React.SetStateAction<string>>
}

const Footer: React.FC<FooterProps> = ({ className }) => {
  const year = new Date().getFullYear()

  return (
    <footer className={classNames(className, 'space-x-3 text-xs')}>
      <a
        href="https://www.camid.eu"
        target="_blank"
        rel="noopener noreferrer"
        className="whitespace-no-wrap hover:underline focus:underline"
      >
        <IconCopyright className="inline-block size-4 align-text-top" /> {year}{' '}
        <FormattedMessage {...translationsShared.footerCompany} />
      </a>
      <a
        href="https://www.camid.eu/impressum"
        target="_blank"
        rel="noopener noreferrer"
        className="hover:underline focus:underline"
      >
        <FormattedMessage {...translationsShared.footerImprint} />
      </a>
      <a
        href="https://www.camid.eu/privacy"
        target="_blank"
        rel="noopener noreferrer"
        className="hover:underline focus:underline"
      >
        <FormattedMessage {...translationsShared.footerPrivacy} />
      </a>
    </footer>
  )
}

export { Footer }
