import React, { ReactNode } from 'react'
import { ErrorMessage, useField } from 'formik'
import { FieldErrorMessage } from './FieldErrorMessage'
import { MultiSelectField, MultiSelectFieldProps } from './MultiSelectFIeld'
import { InputLabel } from './InputLabel'

type Props<Option, Error = unknown> = MultiSelectFieldProps<Option, Error> & {
  label: ReactNode
  name: string
}

export function MultiSelectFormField<Option, Error = unknown>({
  label,
  name,
  ...props
}: Props<Option, Error>) {
  const [field, , helpers] = useField(name)
  return (
    <div className="flex-1 flex-col items-center">
      <div className="items-center ">
        <InputLabel htmlFor={name} label={label} />
        <div className={'flex'}>
          <MultiSelectField
            {...props}
            value={field.value}
            onChange={(value) => helpers.setValue(value)}
          />
        </div>
      </div>
      <ErrorMessage name={name} component={FieldErrorMessage} />
    </div>
  )
}
