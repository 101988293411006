import classNames from 'classnames'
import { PropsWithChildren } from 'react'

import { Portal as HeadlessPortal } from '@headlessui/react'

export type PortalProps = PropsWithChildren<{ className?: string }>

export function Portal({ children, className }: PortalProps) {
  return (
    <HeadlessPortal>
      <div className={classNames('react-ui-base', className)}>{children}</div>
    </HeadlessPortal>
  )
}
