import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Formik, FormikHelpers } from 'formik'
import { AsyncView } from '@aboutbits/react-toolbox'
import { CancelButton } from '../../../shared/form/button/CancelButton'
import { SubmitButton } from '../../../shared/form/button/SubmitButton'
import { SectionActions } from '../../../shared/section/SectionActions'
import { FileFormSection } from '../../file/form/FileFormSection'
import { LeaveDirtyFormPrompt } from '../../../shared/form/LeaveDirtyFormPrompt'
import { ActivityFieldState, Group } from '../types'
import { AuthContext } from '../../../auth/AuthContext'
import { useGetFilteredCategoriesForActivityGroup } from '../utils'
import { ActivityFormFields } from './types'
import { GeneralFormSection } from './GeneralFormSection'
import { TimingFormSection } from './TimingFormSection'
import { ProjectFormSection } from './ProjectFormSection'
import { ArticleFormSection } from './ArticleFormSection'
import { AddressFormSection } from './AddressFormSection'
import { useGetActivityValidationSchema } from './formValidation'
import { NotificationSection } from './NotificationSection'
import { replaceUserAndDate } from './replaceUserAndDate'

type Props = {
  initialValues: ActivityFormFields
  activityGroup: Group
  onSubmit: (
    values: ActivityFormFields,
    formikHelpers: FormikHelpers<ActivityFormFields>
  ) => void
  showNotifications?: boolean
}

const ActivityForm: React.FC<Props> = ({
  initialValues,
  onSubmit,
  activityGroup,
}) => {
  const { data } = useContext(AuthContext)
  const navigate = useNavigate()

  const [isUploading, setIsUploading] = useState(false)
  const [activityFormConfig, setActivityFormConfig] = useState<Group | null>(
    null
  )

  const categories = useGetFilteredCategoriesForActivityGroup(activityGroup)

  useEffect(() => {
    if (activityFormConfig == null) {
      setActivityFormConfig(activityGroup)
    }
  }, [activityFormConfig, activityGroup])

  const schema = useGetActivityValidationSchema(activityFormConfig)

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={schema}
    >
      {(props) => (
        <AsyncView
          data={categories}
          renderSuccess={(categories) => (
            <Form>
              <LeaveDirtyFormPrompt />
              <GeneralFormSection
                activityGroup={activityGroup}
                categories={categories}
                formik={props}
                fieldsConfig={activityFormConfig?.fields}
              />
              <TimingFormSection fieldsConfig={activityFormConfig?.fields} />
              {activityFormConfig?.fields.addressId.state !==
                ActivityFieldState.Hidden && (
                <AddressFormSection
                  formik={props}
                  aria-required={activityFormConfig?.fields.addressId.required}
                  fieldState={activityFormConfig?.fields.addressId.state}
                />
              )}
              {activityFormConfig?.fields.projectId.state !==
                ActivityFieldState.Hidden && (
                <ProjectFormSection
                  formik={props}
                  aria-required={activityFormConfig?.fields.projectId.required}
                  addressHidden={
                    activityFormConfig?.fields.addressId.state !==
                    ActivityFieldState.Hidden
                  }
                  fieldState={activityFormConfig?.fields.projectId.state}
                />
              )}
              {activityFormConfig?.fields.articles.state !==
                ActivityFieldState.Hidden && (
                <ArticleFormSection
                  formik={props}
                  aria-required={activityFormConfig?.fields.articles.required}
                  selectableIds={
                    activityFormConfig?.fields.articles.selectableIds ?? []
                  }
                  fieldState={activityFormConfig?.fields.articles.state}
                />
              )}
              {activityFormConfig?.fields.notifications.state !==
                ActivityFieldState.Hidden && (
                <NotificationSection activityGroup={activityGroup} />
              )}
              {activityFormConfig?.fields.files.state !==
                ActivityFieldState.Hidden && (
                <FileFormSection
                  name="files"
                  onUploading={setIsUploading}
                  defaultDescripton={replaceUserAndDate(
                    activityFormConfig?.fields.files.default?.description ?? '',
                    data?.user.username ?? ''
                  )}
                  fieldState={activityFormConfig?.fields.files.state}
                />
              )}
              <SectionActions className="py-5 md:py-5">
                <CancelButton
                  disabled={isUploading}
                  onClick={() => navigate(-1)}
                />
                <SubmitButton disabled={isUploading} />
              </SectionActions>
            </Form>
          )}
        />
      )}
    </Formik>
  )
}

export { ActivityForm }
