import { useEffect, useState } from 'react'

import { PromiseExtended } from 'dexie'
import { Notification } from '../types'
import { ListResponse, TABLE_NOTIFICATIONS } from '../../../db/types'
import { db } from '../../../db'
import { attachTableListener, useGetById } from '../../../db/hooks'
import { updateDatabase } from '../../../shared/utils/syncUtil'
import { ResourceType } from '../../../shared/utils/resourceTypes'

function generateIndexedSort(notification: Notification) {
  return notification.sort ?? ''
}

const overrideNotifications = async (
  notifications: Notification[]
): Promise<void> => {
  const enrichedNotifications = notifications.map((notification) => {
    return {
      ...notification,
      sortIndexed: generateIndexedSort(notification),
    }
  })

  await updateDatabase(db, TABLE_NOTIFICATIONS, enrichedNotifications)
}

const overrideNotification = async (
  notification: Notification
): Promise<void> => {
  await db.table(TABLE_NOTIFICATIONS).put({
    ...notification,
    sortIndexed: generateIndexedSort(notification),
  })
}

const useGetFilteredNotificationsForUser = (
  userId: string
): ListResponse<Notification> => {
  const [data, setData] = useState<Array<Notification> | null>(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    function queryAndSetState() {
      return db
        .table(TABLE_NOTIFICATIONS)
        .filter(
          (notification: Notification) =>
            notification.userId === userId &&
            !notification.archived &&
            notification.visibility.dashboard
        )
        .sortBy('sortIndexed')
        .then((items) => {
          setData(items)
          setError(null)
        })
        .catch((error) => {
          setError(error)
          setData(null)
        })
    }

    queryAndSetState()

    return attachTableListener(queryAndSetState, TABLE_NOTIFICATIONS)
  }, [userId])

  return { data, error }
}

const getNotificationsByResource = (
  resourceType: ResourceType,
  resourceId: string
): PromiseExtended<Notification[]> => {
  return db
    .table(TABLE_NOTIFICATIONS)
    .filter(
      (notification: Notification) =>
        notification.referencedResourceType === resourceType &&
        notification.referencedResourceId === resourceId &&
        !notification.archived
    )
    .sortBy('sortIndexed')
}

const useGetNotificationsByResource = (
  resourceType: ResourceType,
  resourceId: string
): ListResponse<Notification> => {
  const [data, setData] = useState<Array<Notification> | null>(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    function queryAndSetState() {
      return getNotificationsByResource(resourceType, resourceId)
        .then((items) => {
          setData(items)
          setError(null)
        })
        .catch((error) => {
          setError(error)
          setData(null)
        })
    }

    queryAndSetState()

    return attachTableListener(queryAndSetState, TABLE_NOTIFICATIONS)
  }, [resourceType, resourceId])

  return { data, error }
}

const useGetNotificationsByResources = (
  resourceType: ResourceType,
  resourceIds: string[]
): ListResponse<Notification> => {
  const [data, setData] = useState<Array<Notification> | null>(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    function queryAndSetState() {
      return db
        .table(TABLE_NOTIFICATIONS)
        .filter(
          (notification: Notification) =>
            notification.referencedResourceType === resourceType &&
            resourceIds.includes(notification.referencedResourceId) &&
            !notification.archived
        )
        .sortBy('sortIndexed')
        .then((items) => {
          setData(items)
          setError(null)
        })
        .catch((error) => {
          setError(error)
          setData(null)
        })
    }

    queryAndSetState()

    return attachTableListener(queryAndSetState, TABLE_NOTIFICATIONS)
  }, [resourceType, resourceIds])

  return { data, error }
}

const useGetNotificationById = (id: string) =>
  useGetById<Notification>(TABLE_NOTIFICATIONS, id)

const addNotification = async (notification: Notification): Promise<void> => {
  await overrideNotification(notification)
}

const addNotifications = async (
  notifications: Notification[]
): Promise<void> => {
  await overrideNotifications(notifications)
}

const deleteNotification = async (id: string): Promise<void> => {
  await db.table(TABLE_NOTIFICATIONS).delete(id)
}

export {
  overrideNotifications,
  overrideNotification,
  useGetFilteredNotificationsForUser,
  useGetNotificationsByResources,
  useGetNotificationsByResource,
  getNotificationsByResource,
  useGetNotificationById,
  addNotification,
  addNotifications,
  deleteNotification,
}
