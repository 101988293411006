import React, { ReactNode, useEffect, useRef } from 'react'
import { useIntl } from 'react-intl'
import { IconArrowBack, IconClose } from '../svgs'
import { HeaderLeftArea, HeaderRightArea } from '../header'
import { translationsShared } from '../translations/translationsShared'
import { DialogAction } from './DialogAction'
import { DialogHeaderArea } from './DialogHeaderArea'
import { DialogTitle } from './DialogTitle'
import { DismissType } from './types'

const DialogSearch: React.FC<{
  text: string
  title: ReactNode
  searchLabel: string
  setText: (string: string) => void
  stopSearch: () => void
  dismissType?: DismissType
  onDismiss: () => void
}> = ({
  text,
  title,
  searchLabel,
  setText,
  stopSearch,
  dismissType = DismissType.close,
  onDismiss,
}) => {
  const intl = useIntl()
  const searchInput = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (searchInput.current !== null) {
      searchInput.current.focus()
    }
  }, [])

  return (
    <DialogHeaderArea>
      <HeaderLeftArea className="md:hidden">
        <DialogAction
          label={searchLabel}
          onClick={onDismiss}
          className="rounded-full bg-white"
        >
          {dismissType === DismissType.close && (
            <IconClose
              className="size-7 fill-current text-gray-700"
              title={intl.formatMessage(translationsShared.close)}
            />
          )}
          {dismissType === DismissType.back && (
            <IconArrowBack
              className="size-7 fill-current text-gray-700"
              title={intl.formatMessage(translationsShared.goBack)}
            />
          )}
        </DialogAction>
      </HeaderLeftArea>
      <DialogTitle className="text-white md:hidden">{title}</DialogTitle>
      <div className="flex flex-1 pt-1.5 md:pt-0">
        <div className="mr-4 md:hidden">
          <button
            aria-label={intl.formatMessage(translationsShared.searchBack)}
            onClick={stopSearch}
            className="text-white hover:text-white focus:outline-none"
          >
            <IconArrowBack className="size-6 fill-current" />
          </button>
        </div>
        <div className="flex flex-1 rounded-full bg-gray-300 px-3 py-1 md:bg-transparent md:p-0">
          <input
            ref={searchInput}
            value={text}
            onChange={(ev: React.ChangeEvent<HTMLInputElement>): void =>
              setText(ev.target.value)
            }
            placeholder={intl.formatMessage(
              translationsShared.searchPlaceholder
            )}
            className="w-full flex-1 border-none bg-transparent text-base text-gray-700 outline-none placeholder:text-gray-300 md:text-2xl md:text-white"
          />
        </div>
      </div>
      <HeaderRightArea className="hidden md:block">
        <DialogAction
          label={intl.formatMessage(translationsShared.searchBack)}
          onClick={stopSearch}
          className="rounded-full bg-gray-700 text-white hover:bg-gray-600"
        >
          <IconClose
            className="size-7 fill-current"
            title={intl.formatMessage(translationsShared.close)}
          />
        </DialogAction>
      </HeaderRightArea>
    </DialogHeaderArea>
  )
}

export { DialogSearch }
