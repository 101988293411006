import React from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import IconKeyboardArrowRight from '@aboutbits/react-material-icons/dist/IconKeyboardArrowRight'
import {
  Section,
  SectionItem,
  SectionList,
  SectionTitle,
} from '../../../shared/section'
import { useGetProjectById } from '../../project/db/projects'
import { getProjectLink } from '../../project/utils'

const ReferencedProject: React.FC<{
  id: string
}> = ({ id }) => {
  const { data: project } = useGetProjectById(id)
  const link = getProjectLink(id)

  return project ? (
    <Section>
      <SectionTitle>
        <FormattedMessage
          id="notification.resource.project"
          defaultMessage="Project"
        />
      </SectionTitle>
      <SectionList>
        <Link to={link} className="block">
          <SectionItem>
            <h2 className="flex-1">{project.name}</h2>
            <IconKeyboardArrowRight className="size-6" />
          </SectionItem>
        </Link>
      </SectionList>
    </Section>
  ) : null
}

export { ReferencedProject }
