import { Activity, Group } from '../../types'
import { ListChipContainer } from '../../../../shared/listChip/ListChip'
import { ActivityOverviewNotificationChip } from './ActivityOverviewNotificationChip'
import { ActivityOverviewArticlesChip } from './ActivityOverviewArticlesChip'
import { ActivityOverviewFileChip } from './ActivityOverviewFileChip'
import { ActivityOverviewChildActivitiesChip } from './ActivityOverviewChildActivitiesChip'
import { ActivityOverviewParentActivitiesChip } from './ActivityOverviewParentActivitiesChio'

type Props = {
  activity: Activity
  activityGroup: Group
  className?: string
}

export function ActivityOverviewListItemChips({
  activity,
  activityGroup,
  className,
}: Props) {
  return (
    <ListChipContainer className={className}>
      {!activityGroup.listConfig.item.articles.hidden &&
        activity.articles.length > 0 && (
          <ActivityOverviewArticlesChip articles={activity.articles} />
        )}
      {!activityGroup.listConfig.item.files.hidden &&
        activity.files.length > 0 && (
          <ActivityOverviewFileChip files={activity.files} />
        )}
      {!activityGroup.listConfig.item.notifications.hidden && (
        <ActivityOverviewNotificationChip activityId={activity.id} />
      )}

      {!activityGroup.listConfig.item.subActivities.hidden && (
        <>
          <ActivityOverviewChildActivitiesChip activityId={activity.id} />
          <ActivityOverviewParentActivitiesChip activityId={activity.id} />
        </>
      )}
    </ListChipContainer>
  )
}
