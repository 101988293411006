import React, { ComponentType } from 'react'
import classnames from 'classnames'
import { IconProps } from '../svgs/types'

const Badge: React.FC<
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLSpanElement>,
    HTMLSpanElement
  > & { icon?: ComponentType<IconProps> }
> = ({ children, className, icon: Icon, ...props }) => {
  return (
    <span
      className={classnames(
        className,
        'inline-flex items-center rounded-full bg-gray-500 px-1.5 py-0.5 text-xs'
      )}
      {...props}
    >
      {Icon && (
        <Icon
          className={classnames(children ? 'mr-1' : '', 'size-3 fill-current')}
        />
      )}
      {children}
    </span>
  )
}

export { Badge }
