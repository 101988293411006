import { useIntl } from 'react-intl'
import React from 'react'
import IconFormatListBulleted from '@aboutbits/react-material-icons/dist/IconFormatListBulleted'
import {
  FloatingMenu,
  FloatingMenuButtonLink,
} from '../../../shared/floatingMenu/FloatingMenu'
import { IconEdit, IconNotifications } from '../../../shared/svgs'
import { translationsNotification } from '../../notification/translations/translationsNotification'
import { translationsShared } from '../../../shared/translations/translationsShared'
import { FloatingButton } from '../../../shared/floatingArea'

type Props = {
  addressId: string
  canEdit: boolean
  canAddActivity: boolean
  setShowActivityAddDialog: (show: boolean) => void
}

export function AddressDetailFloatingMenu({
  addressId,
  canEdit,
  canAddActivity,
  setShowActivityAddDialog,
}: Props) {
  const intl = useIntl()

  return (
    <FloatingMenu>
      {canAddActivity && (
        <FloatingButton
          className={'p-3'}
          onClick={() => {
            setShowActivityAddDialog(true)
          }}
        >
          <IconFormatListBulleted
            className={'size-4'}
            title={intl.formatMessage({
              id: 'address.detail.floatingMenu.addActivity',
              defaultMessage: 'Add activity',
            })}
          />
        </FloatingButton>
      )}
      <FloatingMenuButtonLink to={`/addresses/${addressId}/notifications/add`}>
        <IconNotifications
          className="size-4 fill-current"
          title={intl.formatMessage(translationsNotification.resourceAdd)}
        />
      </FloatingMenuButtonLink>
      {canEdit && (
        <FloatingMenuButtonLink to={`/addresses/${addressId}/edit`}>
          <IconEdit
            className="size-4 fill-current"
            title={intl.formatMessage(translationsShared.edit)}
          />
        </FloatingMenuButtonLink>
      )}
    </FloatingMenu>
  )
}
