import { FormattedMessage, useIntl } from 'react-intl'
import { AsyncView } from '@aboutbits/react-toolbox'
import IconAdd from '@aboutbits/react-material-icons/dist/IconAdd'
import { ContentArea } from '../../../shared/content'
import { useGetAddresses, useGetFavoriteAddresses } from '../db/addresses'
import { ButtonInternalLink } from '../../../shared/button'
import { Variant } from '../../../shared/button/Button'
import { SectionActions } from '../../../shared/section/SectionActions'
import { SectionListLoading } from '../../../shared/section/SectionListLoading'
import { FloatingArea, FloatingLinkButton } from '../../../shared/floatingArea'
import { Help } from '../../../shared/help/Help'
import { useHasModulePermission } from '../../../auth/hooks'
import { Module, Permission } from '../../../auth/types'
import { translationsAddress } from '../translations/translationsAddress'
import { Address } from '../types'
import { AddressOverviewList } from './AddressOverviewList'
import { AddressOverviewFavorites } from './AddressOverviewFavorites'

type Props = {
  searching: boolean
  search: string
  page: number
  size: number
  filter?: (item: Address) => boolean
  selectedAddressTypeIds: string[]
}
const AddressOverview: React.FC<Props> = ({
  searching,
  search,
  page,
  size,
  filter,
  selectedAddressTypeIds,
}) => {
  const intl = useIntl()
  const title = searching
    ? intl.formatMessage({
        id: 'address.overview.search.result',
        defaultMessage: 'Search result',
      })
    : intl.formatMessage(translationsAddress.overviewList)

  const empty = searching
    ? intl.formatMessage({
        id: 'address.overview.search.empty',
        defaultMessage: 'No addresses could be found',
      })
    : intl.formatMessage(translationsAddress.overviewListEmpty)

  const { data: favoriteData } = useGetFavoriteAddresses(selectedAddressTypeIds)

  const { data: listData } = useGetAddresses(search, page, size, filter)

  const hasCreatePermissions = useHasModulePermission(
    Module.addresses,
    Permission.create
  )

  return (
    <>
      <ContentArea>
        {page === 1 && search === '' && (
          <AsyncView
            data={favoriteData}
            renderSuccess={(favoriteData) =>
              favoriteData && (
                <AddressOverviewFavorites addresses={favoriteData} />
              )
            }
          />
        )}
        <AsyncView
          data={listData}
          renderLoading={<SectionListLoading title={title} />}
          renderSuccess={(listData) =>
            listData && (
              <AddressOverviewList
                title={title}
                empty={empty}
                data={listData}
              />
            )
          }
        />
        {hasCreatePermissions && (
          <SectionActions>
            <ButtonInternalLink to="/addresses/add" variant={Variant.primary}>
              <span className="flex h-full items-center justify-center px-6">
                <FormattedMessage {...translationsAddress.addTitle} />
              </span>
            </ButtonInternalLink>
          </SectionActions>
        )}
        <Help href="https://camid.eu" />
      </ContentArea>
      {hasCreatePermissions && (
        <FloatingArea>
          <FloatingLinkButton to={`/addresses/add`}>
            <IconAdd
              className="size-6 fill-current"
              title={intl.formatMessage(translationsAddress.addTitle)}
            />
          </FloatingLinkButton>
        </FloatingArea>
      )}
    </>
  )
}

export { AddressOverview }
