import IconReceiptLong from '@aboutbits/react-material-icons/dist/IconReceiptLong'
import { FormattedMessage } from 'react-intl'
import { useState } from 'react'
import { ActivityArticle } from '../../types'
import { ListChip } from '../../../../shared/listChip/ListChip'
import { ActivityOverviewArticleChipDialog } from './dialog/ActivityOverviewArticleChipDialog'

export function ActivityOverviewArticlesChip({
  articles,
}: {
  articles: ActivityArticle[]
}) {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  return (
    <>
      <ListChip Icon={IconReceiptLong} onClick={() => setIsOpen(true)}>
        <>
          <FormattedMessage
            id="activity.overview.list.item.chips.articles"
            defaultMessage="Articles"
          />
          {` (${articles.length})`}
        </>
      </ListChip>
      <ActivityOverviewArticleChipDialog
        articles={articles}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </>
  )
}
