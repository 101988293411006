import React from 'react'
import { PaginatedData } from '../../../db/types'
import { Activity, Group, GroupListView } from '../types'
import {
  Section,
  SectionList,
  SectionPaginationRouter,
  SectionTitle,
} from '../../../shared/section'
import { Empty } from '../../../shared/empty'
import { IconFormatListBulleted } from '../../../shared/svgs'
import { ActivityOverviewItemLink } from './ActivityOverviewItem'
import { usePopulateActivity } from './populateActivityData'

type Props = {
  title: string
  empty: string
  activityGroup: Group & { listView: GroupListView.classic }
  data: PaginatedData<Activity>
}

const ActivityOverviewViewList: React.FC<Props> = ({
  empty,
  data,
  title,
  activityGroup,
}) => {
  const items = usePopulateActivity(data, activityGroup)

  return (
    <Section>
      <SectionTitle>{title}</SectionTitle>
      <SectionList>
        {items.map((activity) => (
          <ActivityOverviewItemLink
            activity={activity}
            key={activity.id}
            activityGroup={activityGroup}
          />
        ))}
        {items.length === 0 && (
          <Empty icon={IconFormatListBulleted} text={empty} />
        )}
        <SectionPaginationRouter
          page={data.page}
          size={data.size}
          total={data.total}
        />
      </SectionList>
    </Section>
  )
}

export { ActivityOverviewViewList }
