import React from 'react'
import { Form, Formik } from 'formik'
import { useNavigate } from 'react-router-dom'
import { formatDateForAPI } from '../../../shared/utils/apiUtils'
import { SubmitButton } from '../../../shared/form/button/SubmitButton'
import { CancelButton } from '../../../shared/form/button/CancelButton'
import { SectionActions } from '../../../shared/section/SectionActions'
import { NotificationEditFormFields } from './types'
import { GeneralEditSection } from './GeneralEditSection'
import { useGetEditNotificationValidationSchema } from './notificationFormValidation'
import { AssignNotificationEditSection } from './AssignNotificationEditSection'

const NotificationEditForm: React.FC<{
  initialValues: NotificationEditFormFields
  onSubmit: (values: NotificationEditFormFields) => void
  edit?: boolean
}> = ({ initialValues, onSubmit, edit }) => {
  const navigate = useNavigate()

  const schema = useGetEditNotificationValidationSchema()

  const prepareAndSubmit = (values: NotificationEditFormFields) => {
    onSubmit({
      ...values,
      userId: values.userId || null,
      addressGroupId: values.addressGroupId || null,
      time: values.time ? formatDateForAPI(values.time) : null,
    })
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values: NotificationEditFormFields) =>
        prepareAndSubmit(values)
      }
      validationSchema={schema}
      validateOnChange={false}
    >
      {(props) => (
        <Form>
          <GeneralEditSection formik={props} />
          <AssignNotificationEditSection formik={props} />
          <SectionActions>
            <CancelButton onClick={() => navigate(-1)} />
            <SubmitButton />
          </SectionActions>
        </Form>
      )}
    </Formik>
  )
}

export { NotificationEditForm }
