import React, { ReactElement, useState } from 'react'
import {
  FormattedDate,
  FormattedMessage,
  FormattedTime,
  useIntl,
} from 'react-intl'
import { FieldArray, FieldArrayRenderProps } from 'formik'
import classnames from 'classnames'
import { AsyncView } from '@aboutbits/react-toolbox'
import { ContentArea } from '../../../shared/content'
import {
  Section,
  SectionItem,
  SectionList,
  SectionTitle,
} from '../../../shared/section'
import { SectionListAddButton } from '../../../shared/section/SectionListAddButton'
import {
  NotificationAddFormFields,
  NotificationAssignmentType,
  NotificationEditFormFields,
} from '../../notification/forms/types'
import { Size } from '../../../shared/section/SectionItem'
import { useGetAllUsers } from '../../user/db/users'
import { User } from '../../user/types'
import { AddressGroup } from '../../address/types'
import { useGetAddressGroupsForNotifications } from '../../address/db/addressGroups'
import { SectionItemActionContainer } from '../../../shared/section/SectionItemActionContainer'
import { SectionItemActionButton } from '../../../shared/section/SectionItemActionButton'
import { IconDelete } from '../../../shared/svgs'
import { populateNotificationData } from '../../notification/overview/populateNotificationData'
import { Notification, ViewNotification } from '../../notification/types'
import { ActivityFieldState, Group } from '../types'
import { translationsShared } from '../../../shared/translations/translationsShared'
import { translationsActivity } from '../translations/translationsActivity'
import { translationsNotificationType } from '../../notification/translations/translationsNotification'
import { NotificationEditDialog } from './NotificationDialog/NotificationEditDialog'
import { NotificationAddDialog } from './NotificationDialog/NotificationAddDialog'

function NotificationSection({
  activityGroup,
}: {
  activityGroup: Group
}): ReactElement {
  const intl = useIntl()

  const { data: usersData } = useGetAllUsers()
  const { data: addressGroups } = useGetAddressGroupsForNotifications()

  const [showEditDialog, setShowEditDialog] = useState<boolean>(false)
  const [showAddDialog, setShowAddDialog] = useState<boolean>(false)

  const [notificationFormValues, setNotificationFormValues] =
    useState<NotificationEditFormFields | null>(null)
  const [indexOfSelectedRecord, setIndexOfSelectedRecord] = useState<
    number | null
  >(null)

  let arrayHelperBinding: FieldArrayRenderProps

  const toggleSelectedRecord = (id: number) => {
    if (indexOfSelectedRecord === id) {
      setIndexOfSelectedRecord(null)
      setNotificationFormValues(null)
    } else {
      setIndexOfSelectedRecord(id)
    }
  }

  const onConfirmEdit = (values: NotificationEditFormFields) => {
    if (indexOfSelectedRecord != null) {
      arrayHelperBinding.form.setFieldValue(
        `notifications[${indexOfSelectedRecord}]`,
        values
      )
    }
    setShowEditDialog(false)
  }

  const onConfirmAdd = (values: NotificationAddFormFields) => {
    const { users, addressGroups, ...baseNotification } = values

    switch (values.assignmentType) {
      case NotificationAssignmentType.none:
        arrayHelperBinding.push({
          ...baseNotification,
          userId: null,
          addressGroupId: null,
        })
        break
      case NotificationAssignmentType.user:
        values.users.forEach((user) => {
          arrayHelperBinding.push({
            ...baseNotification,
            userId: user.id,
            addressGroupId: null,
          })
        })
        break
      case NotificationAssignmentType.addressGroup:
        values.addressGroups.forEach((addressGroup) => {
          arrayHelperBinding.push({
            ...baseNotification,
            userId: null,
            addressGroupId: addressGroup.id,
          })
        })
        break
    }

    setShowAddDialog(false)
  }

  return (
    <ContentArea>
      <div className="mb-10 space-y-5">
        <Section>
          <SectionTitle>
            <FormattedMessage
              id="activity.notification.title"
              defaultMessage="Notification"
            />
          </SectionTitle>
          <SectionList>
            <AsyncView
              data={usersData}
              renderSuccess={(users) => (
                <FieldArray
                  name="notifications"
                  render={(helper) => {
                    arrayHelperBinding = helper
                    var notifications = helper.form.values.notifications

                    return (
                      <NotificationFormList
                        notifications={notifications}
                        users={users}
                        addressGroups={addressGroups}
                        indexOfSelectedRecord={indexOfSelectedRecord}
                        onSelect={(index) => toggleSelectedRecord(index)}
                        onEdit={(index) => {
                          setNotificationFormValues(
                            helper.form.values.notifications[index]
                          )
                          setShowEditDialog(true)
                        }}
                        onDelete={(index) => {
                          helper.remove(index)
                          toggleSelectedRecord(index)
                          setNotificationFormValues(null)
                        }}
                      />
                    )
                  }}
                />
              )}
            />
          </SectionList>
        </Section>
        {activityGroup.fields.notifications.state !==
          ActivityFieldState.ReadOnly && (
          <SectionListAddButton
            text={intl.formatMessage(translationsActivity.notificationAdd)}
            onClick={() => setShowAddDialog(true)}
          />
        )}
      </div>
      <NotificationEditDialog
        onDismiss={() => setShowEditDialog(false)}
        onConfirm={onConfirmEdit}
        providedValues={notificationFormValues}
        activityGroup={activityGroup}
        isOpen={showEditDialog}
      />
      <NotificationAddDialog
        isOpen={showAddDialog}
        activityGroup={activityGroup}
        onDismiss={() => setShowAddDialog(false)}
        onConfirm={onConfirmAdd}
      />
    </ContentArea>
  )
}

function NotificationFormList({
  notifications,
  users,
  addressGroups,
  indexOfSelectedRecord,
  onSelect,
  onEdit,
  onDelete,
}: {
  notifications: Notification[]
  users: User[] | null
  addressGroups: AddressGroup[] | null
  indexOfSelectedRecord: number | null
  onSelect: (index: number) => void
  onEdit: (index: number) => void
  onDelete: (index: number) => void
}): ReactElement {
  const viewNotifications = populateNotificationData(notifications, {
    users,
    addressGroups,
  })

  if (
    notifications == null ||
    viewNotifications == null ||
    notifications.length === 0
  ) {
    return (
      <SectionItem>
        <FormattedMessage
          id="activity.notification.empty"
          defaultMessage="No Notification"
        />
      </SectionItem>
    )
  }

  return (
    <>
      {viewNotifications.map((notification, index) => (
        <NotificationFormItem
          notification={notification}
          key={index}
          showActions={indexOfSelectedRecord === index}
          onSelect={() => onSelect(index)}
          onEdit={() => onEdit(index)}
          onDelete={() => onDelete(index)}
        />
      ))}
    </>
  )
}

function NotificationFormItem({
  notification,
  showActions,
  onSelect,
  onEdit,
  onDelete,
}: {
  notification: ViewNotification
  showActions: boolean
  onSelect: () => void
  onEdit: () => void
  onDelete: () => void
}): ReactElement {
  const intl = useIntl()
  const assignee = notification.assignedName

  return (
    <div
      className={classnames(
        showActions ? 'bg-white shadow-lg' : '',
        'divide-y divide-gray-500'
      )}
    >
      <>
        <button
          className="block w-full text-left focus:outline-none"
          type="button"
          onClick={onSelect}
        >
          <SectionItem size={assignee == null ? Size.md : Size.xs}>
            <div className="flex-1">
              <h2>
                <FormattedMessage
                  {...translationsNotificationType(notification.type)}
                />
              </h2>
              {assignee && (
                <h3 className="break-all pt-1 text-sm">{assignee}</h3>
              )}
            </div>
            {notification.time && (
              <div className="break-all">
                <FormattedDate value={new Date(notification.time)} />{' '}
                <FormattedTime value={new Date(notification.time)} />
              </div>
            )}
          </SectionItem>
        </button>
        {showActions && (
          <SectionItemActionContainer>
            <SectionItemActionButton
              onClick={onEdit}
              label={intl.formatMessage(translationsShared.edit)}
            />
            <SectionItemActionButton
              onClick={onDelete}
              icon={IconDelete}
              label={intl.formatMessage(translationsShared.delete)}
            />
          </SectionItemActionContainer>
        )}
      </>
    </div>
  )
}

export { NotificationSection }
