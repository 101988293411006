import React, { useContext, useEffect, useState } from 'react'
import classNames from 'classnames'
import {
  FormattedDate,
  FormattedMessage,
  FormattedTime,
  useIntl,
} from 'react-intl'
import { Dialog } from '@reach/dialog'
import IconCalendarMonth from '@aboutbits/react-material-icons/dist/IconCalendarMonth'
import IconMail from '@aboutbits/react-material-icons/dist/IconMail'
import IconCall from '@aboutbits/react-material-icons/dist/IconCall'
import IconTask from '@aboutbits/react-material-icons/dist/IconTask'
import IconChecklist from '@aboutbits/react-material-icons/dist/IconChecklist'
import { LogoLeft } from '../../shared/logo'
import { Button, ButtonVariant } from '../../shared/button'
import { logout } from '../../auth/logout'
import {
  IconAlarm,
  IconClose,
  IconDashboard,
  IconFormatListBulleted,
  IconPermContactCalendar,
  IconPowerSettingsNew,
  IconStore,
  IconSync,
} from '../../shared/svgs'
import { Footer } from '../shared/Footer'
import { AuthContext } from '../../auth/AuthContext'
import { IconProps } from '../../shared/svgs/types'
import { Module } from '../../auth/types'
import { DBContext } from '../../db/DBContext'
import { useGetActivityGroups } from '../../domain/activity/db/activities'
import { Group, GroupIcon } from '../../domain/activity/types'
import { useGetActivityGroupSettings } from '../../domain/activity/db/responsibilityFIlters'
import { APP_VERSION } from '../../config/env'
import { useMenuState, useMenuToggle } from './MenuContext'
import { MenuLink } from './MenuLink'
import { translationsLayouts } from './translations/translationsLayouts'
import { Version } from './Version'

function getActivityIcon(activityGroup: Group): React.FC<IconProps> {
  switch (activityGroup.icon) {
    case GroupIcon.task:
      return IconTask
    case GroupIcon.calendar:
      return IconCalendarMonth
    case GroupIcon.checklist:
      return IconChecklist
    case GroupIcon.mail:
      return IconMail
    case GroupIcon.phone:
      return IconCall
    default:
      return IconFormatListBulleted
  }
}

const Menu: React.FC<{ className?: string }> = ({ className }) => {
  const intl = useIntl()
  const authContext = useContext(AuthContext)
  const { lastSynced, refresh } = useContext(DBContext)
  const [activityGroupFilters, setActivityGroupFilters] = useState<
    Record<string, string>
  >({})

  const modules = authContext.data === null ? [] : authContext.data.modules

  const hasModule = (searchedModule: Module): boolean => {
    const filteredModules = modules.filter((currentModule) => {
      return currentModule.module === searchedModule
    })

    return filteredModules.length > 0
  }

  const { data: activityGroups } = useGetActivityGroups()
  const { data: responsibilityFilters } = useGetActivityGroupSettings()

  useEffect(() => {
    if (responsibilityFilters) {
      setActivityGroupFilters(
        responsibilityFilters.reduce((acc: Record<string, string>, filter) => {
          acc[filter.id] = filter.assignedUserId
          return acc
        }, {})
      )
    }
  }, [responsibilityFilters])

  return (
    <div
      className={classNames(
        className,
        'flex flex-col w-lg shadow-lg rounded-r-lg bg-white'
      )}
    >
      <LogoLeft className="p-8" />
      <div className="border-t-2 border-gray-300" />
      <nav
        aria-label={intl.formatMessage(
          translationsLayouts.navAccessibilityMain
        )}
        className="flex flex-1 flex-col justify-between px-4 py-8"
      >
        <ul className="space-y-6">
          <MenuLink
            text={intl.formatMessage({
              id: 'app.nav.dashboard',
              defaultMessage: 'Dashboard',
            })}
            icon={IconDashboard}
            to="/dashboard"
            locked={false}
          />
          <MenuLink
            text={intl.formatMessage({
              id: 'app.nav.projects',
              defaultMessage: 'Projects',
            })}
            icon={IconStore}
            to="/projects"
            locked={false}
          />
          <MenuLink
            text={intl.formatMessage({
              id: 'app.nav.timeTracking',
              defaultMessage: 'Time Tracking',
            })}
            icon={IconAlarm}
            to="/time-tracking"
            locked={!hasModule(Module.timeTacking)}
          />
          <MenuLink
            text={intl.formatMessage({
              id: 'app.nav.addresses',
              defaultMessage: 'Addresses',
            })}
            icon={IconPermContactCalendar}
            to="/addresses"
            locked={!hasModule(Module.addresses)}
          />
          {activityGroups &&
            activityGroups.map((activityGroup) => (
              <MenuLink
                key={activityGroup.id}
                text={activityGroup.name}
                icon={getActivityIcon(activityGroup)}
                to={
                  '/activity-groups/' +
                  activityGroup.id +
                  (activityGroupFilters[activityGroup.id]
                    ? '?assignedAddress=' +
                      activityGroupFilters[activityGroup.id]
                    : '')
                }
                locked={!hasModule(Module.activities)}
              />
            ))}
        </ul>
        <div>
          <Button
            variant={ButtonVariant.secondary}
            className="mx-4 mt-16 flex flex-row items-center text-sm"
            onClick={logout}
          >
            <IconPowerSettingsNew className="mr-4 size-6" />
            <FormattedMessage id="app.nav.logout" defaultMessage="Logout" />
          </Button>
          <Button
            variant={ButtonVariant.secondary}
            className="mx-4 mt-8 flex flex-row items-center text-sm"
            onClick={refresh}
          >
            <IconSync className="mr-4 size-6" />
            <FormattedMessage id="app.nav.refresh" defaultMessage="Refresh" />
          </Button>
          <div className="mt-8 text-xs leading-snug text-gray-500">
            <FormattedMessage id="app.nav.sync" defaultMessage="Last Synched" />
            {': '}
            {lastSynced ? (
              <>
                <FormattedDate value={lastSynced} />{' '}
                <FormattedTime value={lastSynced} />
              </>
            ) : (
              '-'
            )}
          </div>
          <div className="mt-2 text-xs leading-snug text-gray-500">
            <Version version={APP_VERSION ?? ''} />
          </div>
        </div>
      </nav>
    </div>
  )
}

const DesktopMenu: React.FC = () => {
  return (
    <div className="hidden min-h-screen flex-col md:flex">
      <Menu className="mt-8 flex-1" />
      <Footer className="my-6 ml-2" />
    </div>
  )
}

const MobileMenu: React.FC = () => {
  const intl = useIntl()
  const menuState = useMenuState()
  const menuToggle = useMenuToggle()

  return (
    <Dialog
      isOpen={menuState}
      onDismiss={menuToggle}
      aria-label={intl.formatMessage(translationsLayouts.navAccessibilityMain)}
      className={'md:hidden'}
    >
      <div className="flex min-h-screen flex-row items-stretch">
        <Menu />
        <div className="ml-6 flex items-center">
          <button
            className="rounded-full bg-white p-2 hover:bg-gray-300 focus:bg-gray-300"
            onClick={menuToggle}
            aria-label={intl.formatMessage({
              id: 'app.nav.close',
              defaultMessage: 'Close',
            })}
          >
            <IconClose className="size-8" />
          </button>
        </div>
      </div>
    </Dialog>
  )
}

export { Menu, MobileMenu, DesktopMenu }
