import classNames from 'classnames'
import React from 'react'

export enum Size {
  xs,
  sm,
  md,
}

type SectionItemProps = {
  size?: Size
}

const sectionItemStyles: Record<Size, string> = {
  [Size.xs]: 'px-3 py-3 md:py-3 md:px-5',
  [Size.sm]: 'px-3 py-5 md:py-4 md:px-5',
  [Size.md]: 'px-3 py-5 md:p-5',
}

const SectionItem: React.FC<SectionItemProps & { className?: string }> = ({
  size = Size.md,
  className,
  children,
}) => {
  return (
    <div
      className={classNames(
        'flex items-center space-x-2 md:space-x-4',
        sectionItemStyles[size],
        className
      )}
    >
      {children}
    </div>
  )
}

const SectionItemForm: React.FC = ({ children }) => {
  return (
    <div className="flex flex-col space-y-5 px-3 py-5 md:p-5">{children}</div>
  )
}

export { SectionItem, SectionItemForm }
