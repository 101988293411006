// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function mergeEventHandlers<T extends (...args: any[]) => void>(
  handlers: (T | undefined)[]
) {
  return (...args: Parameters<T>) => {
    handlers.forEach((handler) => {
      if (handler) {
        handler(...args)
      }
    })
  }
}
