import { Dialog } from '@reach/dialog'
import React from 'react'
import { useIntl } from 'react-intl'
import { Activity } from '../../../types'
import { DialogHeaderArea } from '../../../../../shared/dialog/DialogHeaderArea'
import { HeaderLeftArea } from '../../../../../shared/header'
import { DialogAction } from '../../../../../shared/dialog/DialogAction'
import { translationsShared } from '../../../../../shared/translations/translationsShared'
import { IconClose } from '../../../../../shared/svgs'
import { DialogTitle } from '../../../../../shared/dialog/DialogTitle'
import { ContentArea } from '../../../../../shared/content'
import { RelatedActivitiesList } from '../../../related/RelatedActivitiesList'

type Props = {
  relatedActivities: Activity[]
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  dialogTitle: string
  sectionTitle: string
}

export function ActivityOverviewChipRelatedActivitiesDialog({
  relatedActivities,
  isOpen,
  setIsOpen,
  dialogTitle,
  sectionTitle,
}: Props) {
  const intl = useIntl()

  return (
    <Dialog
      isOpen={isOpen}
      aria-label={dialogTitle}
      className="mx-auto bg-white md:my-20 md:w-full md:max-w-lg md:bg-transparent"
    >
      <DialogHeaderArea>
        <HeaderLeftArea>
          <DialogAction
            label={intl.formatMessage(translationsShared.close)}
            onClick={() => setIsOpen(false)}
            className="rounded-full bg-white hover:bg-gray-300"
          >
            <IconClose className="size-7 fill-current text-gray-700" />
          </DialogAction>
        </HeaderLeftArea>
        <DialogTitle className="text-white">{dialogTitle}</DialogTitle>
      </DialogHeaderArea>
      <ContentArea>
        <RelatedActivitiesList
          activities={relatedActivities}
          title={sectionTitle}
          chipsHidden={true}
        />
      </ContentArea>
    </Dialog>
  )
}
