import { Notification, NotificationType } from '../types'

export type NotificationEditFormFields = Omit<Notification, 'type'> & {
  type: NotificationType | ''
  assignmentType: NotificationAssignmentType
}

export enum NotificationAssignmentType {
  none = 'none',
  user = 'user',
  addressGroup = 'addressGroup',
}

export type NotificationAddFormFields = Omit<
  Notification,
  'type' | 'userId' | 'addressGroupId'
> & {
  type: NotificationType | ''
  assignmentType: NotificationAssignmentType
  users: { id: string; name: string }[]
  addressGroups: { id: string; name: string }[]
}
