import { ReactNode } from 'react'

type InputLabelProps = {
  htmlFor: string
  label?: ReactNode
}

export function InputLabel({ htmlFor, label }: InputLabelProps) {
  return (
    <label className="block w-full" htmlFor={htmlFor}>
      <span className="block text-2xs">{label}</span>
    </label>
  )
}
