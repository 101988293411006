import { FormattedMessage, useIntl } from 'react-intl'
import { useState } from 'react'
import IconFormatListNumbered from '@aboutbits/react-material-icons/dist/IconFormatListNumbered'
import { useGetParentActivities } from '../../db/activityHierarchies'
import { ListChip } from '../../../../shared/listChip/ListChip'
import { ActivityOverviewChipRelatedActivitiesDialog } from './dialog/ActivityOverviewChipRelatedActivitiesDialog'

export function ActivityOverviewParentActivitiesChip({
  activityId,
}: {
  activityId: string
}) {
  const intl = useIntl()
  const { data: parentActivities } = useGetParentActivities(activityId)
  const [isOpen, setIsOpen] = useState<boolean>(false)

  if (!parentActivities || parentActivities.length === 0) {
    return null
  }

  return (
    <>
      <ListChip Icon={IconFormatListNumbered} onClick={() => setIsOpen(true)}>
        <>
          <FormattedMessage
            id="activity.overview.list.item.chips.parentActivities"
            defaultMessage="Parent activities"
          />
          {` (${parentActivities.length})`}
        </>
      </ListChip>
      <ActivityOverviewChipRelatedActivitiesDialog
        relatedActivities={parentActivities}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        dialogTitle={intl.formatMessage({
          id: 'activity.overview.parentActivities.dialog.title',
          defaultMessage: 'Parent Activities',
        })}
        sectionTitle={intl.formatMessage({
          id: 'activity.overview.parentActivities.dialog.section.title',
          defaultMessage: 'Parent Activities List',
        })}
      />
    </>
  )
}
