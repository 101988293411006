import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { SectionItem, SectionItemAction } from '../../../shared/section'
import {
  IconFavorite,
  IconFavoriteBorder,
  IconKeyboardArrowRight,
} from '../../../shared/svgs'
import { Address } from '../types'
import { Size } from '../../../shared/section/SectionItem'
import { markAsFavorite, unmarkAsFavorite } from '../api'
import { overrideAddress } from '../db/addresses'
import { extractErrorMessage } from '../../../shared/utils/apiUtils'
import { AppContext } from '../../../app/AppContext'
import { translationsAddress } from '../translations/translationsAddress'
import { AddressTypeBadge } from '../shared/AddressTypeBadge'

const AddressOverviewItem: React.FC<{
  address: Address
}> = ({ address }) => {
  const intl = useIntl()
  const { addAlertErrorMessage } = useContext(AppContext)
  const [disabled, setDisabled] = useState<boolean>(false)

  return (
    <Link to={`/addresses/${address.id}`} className="block">
      <SectionItem size={Size.xs}>
        <SectionItemAction
          icon={address.favorite ? IconFavorite : IconFavoriteBorder}
          label={
            address.favorite
              ? intl.formatMessage(translationsAddress.favoriteUnmark)
              : intl.formatMessage(translationsAddress.favoriteMark)
          }
          disabled={disabled}
          onClick={async () => {
            setDisabled(true)
            try {
              const updatedAddress = await (address.favorite
                ? unmarkAsFavorite(address.id)
                : markAsFavorite(address.id))

              overrideAddress(updatedAddress).finally(() => {
                setDisabled(false)
              })
            } catch (error: any) {
              addAlertErrorMessage(extractErrorMessage(error))
              setDisabled(false)
            }
          }}
        />

        <div className="flex-1">
          <h1 className="mb-1">{address.name}</h1>
          <h2>{address.label}</h2>
        </div>
        <AddressTypeBadge typeId={address.addressTypeId} />
        <div>
          <IconKeyboardArrowRight className="size-6" />
        </div>
      </SectionItem>
    </Link>
  )
}

export { AddressOverviewItem }
