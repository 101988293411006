import React from 'react'
import { Link } from 'react-router-dom'
import { SectionItem } from '../../../shared/section'
import { Size } from '../../../shared/section/SectionItem'
import { IconKeyboardArrowRight } from '../../../shared/svgs'
import { Address } from '../types'
import { AddressTypeBadge } from '../shared/AddressTypeBadge'

const RelatedAddressItem: React.FC<{ data: Address }> = ({ data }) => {
  return (
    <Link to={`/addresses/${data.id}`} className="block">
      <SectionItem size={Size.xs}>
        <div className="flex-1">
          <h1 className="mb-1">{data.name}</h1>
          <h2 className="text-sm">{data.label}</h2>
        </div>
        <AddressTypeBadge typeId={data.addressTypeId} />
        <div>
          <IconKeyboardArrowRight className="size-6" />
        </div>
      </SectionItem>
    </Link>
  )
}

export { RelatedAddressItem }
