import { useState } from 'react'
import { FormattedMessage } from 'react-intl'

export function Version({ version }: { version: string }) {
  const [copied, setCopied] = useState(false)

  const onClick = () => {
    void navigator.clipboard.writeText(version)

    setCopied(true)

    setTimeout(() => {
      setCopied(false)
    }, 2000)
  }

  return (
    <span className="whitespace-nowrap">
      <FormattedMessage id="app.nav.version.label" defaultMessage="Version" />:{' '}
      {copied ? (
        <FormattedMessage
          id="app.nav.version.label.copied"
          defaultMessage="Copied!"
        />
      ) : (
        <button onClick={onClick} className="rounded px-1 hover:bg-gray-300">
          {version.length < 7 ? version : version.slice(-7)}
        </button>
      )}
    </span>
  )
}
